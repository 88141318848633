<template>
  <div id="my-conversations">
    <div class="title">{{ $localization("web-home-my-meets") }}</div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>{{ $localization("web-home-my-meets") }}</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- <div class="buttons-group">
        <el-button>
          <i class="icon-print"></i>
        </el-button>
        <el-button>
          <i class="icon-import"></i>
        </el-button>
      </div> -->
    </div>
    <div class="search-status">
      <div class="status">
        {{ $localization("web-home-show") }}
        <el-select class="primary-input input-bg-white" v-model="$store.state.appointments.filter.PageSize" :placeholder="$localization('web-home-please-select')">
          <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
        </el-select>
        <el-select class="primary-input input-bg-white" v-model="$store.state.appointments.filter.Status" :placeholder="$localization('web-home-please-select')" clearable @clear="$store.state.appointments.filter.Status = null" v-if="getEnums != null">
          <el-option v-for="item in getEnums.find((x) => x.Title == 'AppointmentStatus').Enums" :key="item.Key" :label="$localization('web-enum-appointmentstatus-' + item.Value.toLowerCase())" :value="item.Key"> </el-option>
        </el-select>
      </div>
      <div class="search">
        <el-input class="primary-input input-bg-white" :placeholder="$localization('web-home-search-record')" v-model="$store.state.appointments.filter.SearchTerm" clearable>
          <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
        </el-input>
      </div>
    </div>
    <div class="table">
      <el-table :header-cell-class-name="'table-header'" :data="getAppointments.Appointments">
        <el-table-column label="Id" width="120">
          <template slot-scope="scope"> #{{ scope.row.AppointmentNo }} </template>
        </el-table-column>
        <el-table-column :label="$localization('web-home-doctor-upper')" min-width="290">
          <template slot-scope="scope">
            <div class="user-info" v-if="scope.row.DoctorUser != null">
              <div class="user-avatar" v-if="scope.row.DoctorUser.FirstName != null && scope.row.DoctorUser.LastName != null">
                <div class="short">{{ scope.row.DoctorUser.FirstName[0] + scope.row.DoctorUser.LastName[0] }}</div>
              </div>
              <div class="user-name">
                {{ scope.row.DoctorUser != null ? scope.row.DoctorUser.Title : "" }}
                <span></span>
              </div>
            </div>
            <div v-else>
              <div class="user-name">
                <span>{{ $localization("web-home-doctor-didnot-join") }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="price" :label="$localization('web-home-price-upper')" width="180">
          <template slot-scope="scope"> {{ scope.row.PaymentInfo.PaidPrice }}TL </template>
        </el-table-column>
        <!-- <el-table-column prop="diagnosis" label="TANI">
          <template slot-scope="scope">
            {{ scope.row.PatientDiagnosis.DoctorDiagnosis != null ? scope.row.PatientDiagnosis.DoctorDiagnosis : "..." }}
          </template>
        </el-table-column> -->
        <el-table-column :label="$localization('web-home-meet-time-upper')" width="250" sortable prop="TotalAppointmentDurationInSeconds">
          <template slot-scope="scope">
            <div v-if="scope.row.TotalAppointmentDurationInSeconds / 60 > 1">{{ (scope.row.TotalAppointmentDurationInSeconds / 60).toFixed(0) }} {{ $localization("web-minutes-short") }}</div>
            <div v-else>{{ scope.row.TotalAppointmentDurationInSeconds + " " + $localization("web-seconds-short") }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="status" :label="$localization('web-home-status-upper')" width="180">
          <template slot-scope="scope" v-if="getEnums != null">
            <div class="color-dot">
              <div class="dot" :class="{ yellow: scope.row.Status == 1, red: scope.row.Status == 4, green: scope.row.Status == 3 }"></div>
              {{
                $localization(
                  "web-enum-appointmentstatus-" +
                    getEnums
                      .find((x) => x.Title == "AppointmentStatus")
                      .Enums.find((x) => x.Key == scope.row.Status)
                      .Value.toLowerCase()
                )
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$localization('web-home-meet-date-upper')" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.InterviewInfo.AppointmentDate">{{ $moment(scope.row.InterviewInfo.AppointmentDate).format("DD.MM.YYYY, HH:mm") }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column :label="$localization('web-home-request-date-upper')" width="200">
          <template slot-scope="scope">
            {{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY, HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column style="width: 250px; align: center" width="200"
          ><!-- gorusme butonu sığmadığı için genişletildi width:200 eklendi-->
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.Status != 1 && scope.row.Status != 2"
              ><router-link :to="'kullanici/gorusme-detay/' + scope.row.ID"><i class="icon-dots"></i></router-link
            ></el-button>
            <el-button type="text" class="go-appointments" @click="isOkforPatient(scope.row.ID)" v-else>
              <i class="el-icon-video-camera"></i>
              {{ scope.row.Status == 1 ? $localization("web-home-join-meet") : $localization("web-home-join-meet") }}
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="lottie-container">
            <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
            <div class="no-data-text">{{ $localization("web-home-no-meet-data") }}</div>
            <div class="no-data-text">{{ $localization("web-home-past-meets-will-show-in-this-area") }}</div>
          </div>
        </template>
      </el-table>
    </div>

    <div class="pagination">
      <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page > $store.state.appointments.appointments.TotalCount ? $store.state.appointments.appointments.TotalCount : filter.PageSize * filter.Page }} {{ $localization("web-pagination-info-text") }} {{ $store.state.appointments.appointments.TotalCount }}</div>
      <el-pagination class="pages" background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next" :total="$store.state.appointments.appointments.TotalCount" :small="screenWidth > 550 ? false : true" :pager-count="screenWidth > 550 ? 7 : 5"></el-pagination>
    </div>
    <el-dialog :visible.sync="isPatientOkDialog" :width="screenWidth > 991 ? '40%' : '90%'" class="check-mernis" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog">
        <div class="form">
          <div class="title">{{ $localization("web-home-mic-cam-permission") }}</div>
          <div class="text">{{ $localization("web-home-mic-cam-permission-info-text") }}</div>
          <div class="buttons">
            <el-button class="primary-btn" @click="goAppointments()">{{ $localization("web-home-confirm") }}</el-button>
            <el-button class="secondarary-btn" @click="isPatientOkDialog = false">{{ $localization("web-home-cancel") }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Appointments",
  data() {
    return {
      pagesize: [10, 20, 30, 40],
      isPatientOkDialog: false,
      screenWidth: 0,
      appointmentId: null,
    };
  },
  async created() {
    await this.$store.dispatch("getListUserAppointments", this.$store.state.appointments.filter);
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.pageview({ page_path: this.$route.path, page_title: "Home | Elra Sağlık" });
    }

    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  methods: {
    isOkforPatient(id) {
      this.isPatientOkDialog = true;
      this.appointmentId = id;
    },
    goAppointments() {
      this.isPatientOkDialog = false;
      window.doNotThrowMe = true;
      this.$client.post("/Appointment/ShutdownOtherWindows", {});
      setTimeout(() => {
        window.doNotThrowMe = false;
      }, 1000);
      this.$router.push("/kullanici/gorusme-odasi/" + this.appointmentId);
    },
  },
  computed: {

    getEnums() {
      return this.$store.getters.getEnums;
    },
    getAppointments() {
      return this.$store.getters.getAppointments;
    },
    filter() {
      return this.$store.state.appointments.filter;
    },
  },
  watch: {
    "$store.state.appointments.filter": {
      deep: true,
      async handler() {
        await this.$store.dispatch("getListUserAppointments", this.$store.state.appointments.filter);
      },
    },
  },
};
</script>
<style lang="less">
#my-conversations {
  .join-btn {
    box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
    background-color: #234a98;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    border-radius: 5px;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;

    .user-avatar {
      flex-shrink: 0;
      width: 30px;
      height: 30px;

      .short {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #234a98;
        height: 100%;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8697a8;
  }

  .el-select__caret {
    background: unset;
  }

  .color-dot {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .dot {
      height: 5px;
      width: 5px;
      background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #ff3d57;
      }

      &.green {
        background-color: #09b66d;
      }

      &.yellow {
        background-color: #fdbf5e;
      }
    }
  }
}
</style>

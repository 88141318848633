<template>
  <div id="user-home">
    <home-slider v-if="homeInformationCard.length > 0" :data="homeInformationCard"></home-slider>
    <blogs></blogs>
    <elra-comments></elra-comments>
    <entegration-and-documents></entegration-and-documents>
  </div>
</template>

<script>
import HomeSlider from "@/components/HomeSlider/HomeSlider.vue";
import EntegrationAndDocuments from "@/components/EntegrationAndDocuments/Index.vue";
import ElraComments from "@/components/ElraComments/Index.vue";
import Blogs from "@/components/Blogs/Index.vue";

export default {
  name: "Home",
  components: { ElraComments, EntegrationAndDocuments, HomeSlider, Blogs },
  data() {
    return {
      homeInformationCard: [],
      homeComments: [],
    };
  },
  created() {
    this.getHomeInformationCardData();
    this.getHomeComments();
  },
  methods: {
    goProfile() {
      this.$router.push({ name: "Profilim" });
    },
    async getHomeInformationCardData() {
      const res = await this.$client.post("/AboutUs/GetHomeInformationCards");
      console.log(res);
      this.homeInformationCard = res.data.Data.Items;
    },
    async getHomeComments() {
      const res = await this.$client.post("/AboutUs/GetUserComments");
      console.log(res);
      this.homeComments = res.data.Data.Items;
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style lang="less">
#user-home {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #dce2ff;
}
</style>

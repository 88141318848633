<template>
  <div id="client-profile">
    <p class="p-head">{{ $localization("web-client-my-profile") }}</p>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>{{ $localization("web-client-my-profile") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="profile">
        <div class="profile-top">
          <div class="profile-photo">
            <img v-if="user.Image != null" :src="user.Image" alt="" />
          </div>
          <div class="profile-name">{{ user.FirstName }} {{ user.LastName }}</div>
          <el-button class="primary-btn edit btn-shadow" @click="openEditDialog(0)">
            <i class="icon-drafts"></i> <span>{{ $localization("web-client-edit") }}</span>
          </el-button>
        </div>
        <div class="profile-bottom">
          <div class="item" v-if="user.InvoiceAddress.District != null && user.InvoiceAddress.City != null">
            <i class="icon-location"></i><span>{{ user.InvoiceAddress.District }}, {{ user.InvoiceAddress.City }}</span>
          </div>
          <div class="item" v-if="user.Email != null">
            <i class="icon-message"></i><span>{{ user.Email }}</span>
          </div>
          <div class="item">
            <i class="icon-mobile"></i><span>{{ user.PhoneNumber }}</span>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="address">
          <p>
            {{ $localization("web-client-invoice-address") }}
            <i class="icon-task" @click="openEditDialog(1)" style="cursor: pointer"></i>
          </p>
          <div class="info">
            <label class="data1">{{ $localization("web-client-city") }}</label>
            <label class="data2">{{ user.InvoiceAddress.City }}</label>
          </div>
          <div class="info">
            <label class="data1">{{ $localization("web-client-district") }}</label>
            <label class="data2">{{ user.InvoiceAddress.District }}</label>
          </div>
          <div class="info">
            <label class="data1">{{ $localization("web-client-postal-code") }}</label>
            <label class="data2">{{ user.InvoiceAddress.ZipCode }}</label>
          </div>

          <div class="info address-info">
            <label class="data1">{{ $localization("web-client-address") }}</label>
            <label class="data2">{{ user.InvoiceAddress.Address }}</label>
          </div>
        </div>
        <div class="last-conversations">
          <div class="title">
            <div>{{ $localization("web-client-passappointments") }} ({{ pastTotalCount }})</div>
          </div>
          <div class="profile-slider owl-theme owl-carousel" v-if="pastAppointment.length > 0">
            <div class="slider-item" v-for="item in pastAppointment" :key="item.ID">
              <div class="dr-card" v-if="item.DoctorUser != null">
                <div class="dr-photo">
                  <img :src="item.DoctorUser.Image" alt="" />
                </div>
                <div class="dr-information">
                  <div class="dr-name">
                    {{ item.DoctorUser.Specialist }}
                    {{ item.DoctorUser.Title }}
                  </div>
                  <div class="date">
                    {{ $moment(item.EndDate).format("DD.MM.YYYY") }}
                  </div>
                  <el-button class="primary-btn detail" @click="goDetails(item.ID)"><i class="icon-arrow"></i>{{ $localization("web-client-goto-detail") }}</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-else>
            <div class="lottie-container appointment">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">{{ $localization("web-client-no-past-appointments") }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="end">
        <div class="payment">
          <div class="pay">
            <span>{{ $localization("web-client-saved-cards") }}</span>
            <div class="add-button">
              <el-button class="btn-shadow" @click="showCardDialog = true">
                <i></i>
              </el-button>
            </div>
          </div>
          <div class="payment-top" v-if="user.PaymentMethods.length > 0">
            <CreditCardVue :data="form" />
          </div>
          <div class="payment-bottom" v-if="user.PaymentMethods.length > 0">
            <div class="card-info">
              <label class="data1">{{ $localization("web-client-card-type") }}</label>
              <label class="data2">{{ user.PaymentMethods[0].CardFamily }}</label>
            </div>
            <div class="card-info">
              <label class="data1">{{ $localization("web-client-cardholder") }}</label>
              <label class="data2">{{ user.PaymentMethods[0].Title }}</label>
            </div>

            <div class="card-info">
              <label class="data1">{{ $localization("web-client-cardno") }}</label>
              <label class="data2">{{ user.PaymentMethods[0].Bin }}</label>
            </div>
          </div>
          <div class="empty" v-if="user.PaymentMethods.length == 0">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">{{ $localization("web-client-no-payment-method") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIXME: Tekrar açılmıyor.    v-if="showVerifyDialog"    bununla değiştirilmeli.   :show.sync="showVerifyDialog"     -->
    <VerifyDialog v-if="showVerifyDialog" :otpTimerSecond="expireMinute * 60" :isEmailValidation="isEmailValidation" :tempUser="tempUser" />

    <el-dialog :visible.sync="profileSettings" :width="screenWidth > 991 ? '70%' : '90%'" class="profile-settings-dialog" :close-on-click-modal="false">
      <div class="dialog">
        <div class="close" @click="profileSettings = false">
          <i class="icon-close"></i>
        </div>
        <div class="dialog-left">
          <!-- 
            19.10.2023 ++NC profil resmi kaldırıldı.
          <div class="dialog-left-top">
            <input type="file" @change="uploadFile" style="display: none" ref="file" />
            <div class="image" @click="uploadFiles()" :style="{ backgroundImage: 'url(' + user.Image + ')' }">
              <i class="icon-camera-2"></i>
              <div>{{ $localization('web-client-upload-photo') }}</div>
            </div>
          </div>
        -->

          <div class="dialog-left-bottom">
            <ul>
              <li :class="{ active: activeTab == 0 ? true : false }" @click="activeTab = 0">
                <i class="icon-details"></i>
                <div>{{ $localization("web-client-personal-info") }}</div>
              </li>
              <li :class="{ active: activeTab == 1 ? true : false }" @click="activeTab = 1">
                <i class="icon-truck"></i>
                <div>{{ $localization("web-client-invoice-address") }}</div>
              </li>
              <li :class="{ active: activeTab == 2 ? true : false }" @click="activeTab = 2">
                <i class="icon-credit-card"></i>
                <div>{{ $localization("web-client-saved-cards") }}</div>
              </li>
              <li class="delete-account" @click="deleteAccount">
                <i class="icon-delete"></i>
                <div>{{ $localization("web-client-delete-my-account") }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="dialog-right" v-if="activeTab == 0">
          <div class="container">
            <div class="title2">{{ $localization("web-client-personal-info") }}</div>

            <div class="input-container">
              <div>{{ $localization("web-client-form-name") }}</div>
              <el-input class="login-input-email" v-model="userForm.FirstName" :disabled="user.IsMernisVerified"></el-input>
              <span class="error">{{ $errorMessage("FirstName", ValidationErrors) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization("web-client-form-surname") }}</div>
              <el-input class="login-input-email" v-model="userForm.LastName" :disabled="user.IsMernisVerified"></el-input>
              <span class="error">{{ $errorMessage("LastName", ValidationErrors) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization("web-register-nationality") }}</div>
              <el-select
                class="login-input-email"
                v-if="countryList.length > 0"
                v-model="userForm.CitizenshipId"
                style="width: 100%"
                :placeholder="$localization('web-register-nationality')"
                filterable
                clearable
                @change="
                  userForm.City.ID = null;
                  userForm.District.ID = null;
                "
                :disabled="user.IsMernisVerified">
                <el-option v-for="item in countryList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
              </el-select>
              <span class="error">{{ $errorMessage("CitizenshipId", ValidationErrors) }}</span>
            </div>

            <template v-if="countryList.find((x) => x.ID == userForm.CitizenshipId) && countryList.find((x) => x.ID == userForm.CitizenshipId).Code != 'TR'">
              <div class="input-container">
                <div>{{ $localization("web-client-form-passport-number") }}</div>
                <el-input class="login-input-email" maxlength="30" :placeholder="$localization('web-register-passport-number')" v-model="userForm.PassportNumber"></el-input>
                <span class="error">{{ $errorMessage("PassportNumber", ValidationErrors) }}</span>
              </div>
            </template>
            <template v-else>
              <div class="input-container">
                <div>{{ $localization("web-client-form-identity-number") }}</div>
                <el-input class="login-input-email" maxlength="11" v-mask="mask.Identity" :placeholder="$localization('web-register-indentity-number')" v-model="userForm.IdentityNumber" :disabled="user.IsMernisVerified"></el-input>
                <span class="error">{{ $errorMessage("IdentityNumber", ValidationErrors) }}</span>
              </div>
            </template>

            <div v-if="countryList.find((x) => x.ID == userForm.CitizenshipId) && countryList.find((x) => x.ID == userForm.CitizenshipId).Code == 'TR'">
              <el-row :gutter="8">
                <el-col :span="12">
                  <div class="input-container">
                    <div>{{ $localization("web-register-city") }}</div>
                    <el-select
                      class="login-input-email"
                      v-model="userForm.City.ID"
                      style="width: 100%"
                      :placeholder="$localization('web-register-city')"
                      filterable
                      clearable
                      @change="
                        userForm.District.ID = null;
                        getDistrictList(userForm.City.ID);
                      ">
                      <el-option v-for="item in cityList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                    </el-select>
                    <span class="error">{{ $errorMessage("CityId", ValidationErrors) }}</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="input-container">
                    <div>{{ $localization("web-register-district") }}</div>
                    <el-select class="login-input-email" v-model="userForm.District.ID" style="width: 100%" :placeholder="$localization('web-register-district')" filterable clearable :disabled="userForm.City.ID == null">
                      <el-option v-for="item in districtList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                    </el-select>
                    <span class="error">{{ $errorMessage("DistrictId", ValidationErrors) }}</span>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="profile-phone-container">
              <div class="input-container-2" v-if="userForm.RegisterType == 0">
              <div>{{ "Telefon*" }}</div>
              <div class="login-input">
                <vue-phone-number-input
                  :disabled="Boolean(userForm.RegisterType == 0)"
                  v-model="userForm.PhoneNumber"
                  @update="phoneFormatter = $event"
                  :no-example="true"
                  :no-flags="true"
                  ref="phoneInput2"
                  :translations="{
                    countrySelectorLabel: $localization('web-login-country-code'),
                    countrySelectorError: $localization('web-login-select-country-code'),
                    phoneNumberLabel: $localization('web-login-phone-number'),
                    example: $localization('web-login-example'),
                  }"
                  size="md"
                  class="login-input-phone"
                  default-country-code="TR" />
              </div>
              <span class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
            </div>

            <div class="input-container" v-if="userForm.RegisterType == 1">
              <div>{{ $localization("web-client-form-email") }}</div>
              <el-input class="login-input-email" :disabled="userForm.RegisterType == 1" v-model="userForm.Email"></el-input>
              <span class="error">{{ $errorMessage("Email", ValidationErrors) }}</span>
            </div>
            <div class="input-container">
              <div class="text">{{ $localization("web-client-form-birthdate") }}</div>
              <el-date-picker class="login-input-email" style="width: 100%" v-model="userForm.BirthDate" :disabled="user.IsMernisVerified" format="dd.MM.yyyy" type="date" :placeholder="$localization('web-client-form-please-select')" default-value="1980-01-01" prefix-icon="none"> </el-date-picker>
              <span class="error">{{ $errorMessage("Birthdate", ValidationErrors) }}</span>
            </div>
            </div>


            <div class="input-container">
              <div>{{ $localization("web-client-form-address-optional") }}</div>
              <div class="location">
                <el-input class="login-input-email" :placeholder="$localization('web-client-form-enter-address')" v-model="userForm.Address"></el-input>
                <el-button class="primary-btn" @click="findLocation" :loading="loading" :disabled="loading">{{ $localization("web-client-form-find-address") }} </el-button>
              </div>

              <span class="error">{{ $errorMessage("Address", ValidationErrors) }}</span>
            </div>
            <div class="input-container">
              <div>{{ $localization("web-client-form-gender") }}</div>
              <el-select class="login-input-email" v-model="userForm.Gender" :placeholder="$localization('web-client-form-please-select')" v-if="genderEnums != null">
                <el-option v-for="(item, index) in genderEnums.Enums" :key="index" :label="item.DisplayName" :value="item.Key"> </el-option>
              </el-select>
              <span class="error">{{ $errorMessage("Gender", ValidationErrors) }}</span>
            </div>
            <!-- <div class="input-container">
              <div>{{ $localization("web-client-form-height") }}</div>
              <div class="location">
                <el-input class="primary-input" :placeholder="$localization('web-client-form-enter-height')" v-model="userForm.Height"></el-input>
              </div>
              <span class="error">{{ $errorMessage("Height", ValidationErrors) }}</span>
            </div> -->
            <!-- <div class="input-container">
              <div>{{ $localization("web-client-form-kilo") }}</div>
              <div class="location">
                <el-input class="primary-input" :placeholder="$localization('web-client-form-enter-kilo')" v-model="userForm.Weight"></el-input>
              </div>
              <span class="error">{{ $errorMessage("Weight", ValidationErrors) }}</span>
            </div> -->

            <div class="input-container">
              <div>{{ $localization("web-client-form-institution") }}</div>
              <el-input class="login-input-email" :disabled="userForm.RegisterType == 1" v-model="userForm.Kurum"></el-input>
              <span class="error">{{ $errorMessage("Kurum", ValidationErrors) }}</span>
            </div>

            <el-button class="primary-btn save-button" @click="updateProfileSettings()" :loading="loading" :disabled="loading"><i class="icon-refresh" v-if="!loading"></i>{{ $localization("web-client-form-save") }}</el-button>
          </div>
        </div>
        <div class="dialog-right" v-if="activeTab == 1">
          <div class="container">
            <div class="title2">{{ $localization("web-client-form-my-invoice-address") }}</div>
            <div>{{ $localization("web-client-form-country") }}</div>
            <div class="input-container">
              <el-input class="primary-input" v-model="invoiceForm.Country"></el-input>
              <span class="error">{{ $errorMessage("Country", ValidationErrors) }}</span>
            </div>

            <div>{{ $localization("web-client-form-city") }}</div>
            <div class="input-container">
              <el-input class="primary-input" v-model="invoiceForm.City"></el-input>
              <span class="error">{{ $errorMessage("City", ValidationErrors) }}</span>
            </div>

            <div>{{ $localization("web-client-form-district") }}</div>
            <div class="input-container">
              <el-input class="primary-input" v-model="invoiceForm.District"></el-input>
              <span class="error">{{ $errorMessage("District", ValidationErrors) }}</span>
            </div>
            <div>{{ $localization("web-client-form-long-address") }}</div>
            <div class="input-container">
              <el-input class="primary-input" v-model="invoiceForm.Address"></el-input>
              <span class="error">{{ $errorMessage("Address", ValidationErrors) }}</span>
            </div>

            <div>{{ $localization("web-client-form-postal-code") }}</div>
            <div class="input-container">
              <el-input class="primary-input" v-model="invoiceForm.ZipCode"></el-input>
              <span class="error">{{ $errorMessage("ZipCode", ValidationErrors) }}</span>
            </div>
            <div>{{ $localization("web-client-form-phone") }}</div>
            <div class="input-container">
              <el-input class="primary-input" v-model="invoiceForm.PhoneNumber"></el-input>
              <span class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
            </div>
            <div>{{ $localization("web-client-form-identity-number-required") }}</div>
            <div class="input-container">
              <el-input class="primary-input" v-model="invoiceForm.IdentityNo" v-mask="mask.Identity"></el-input>
              <span class="error">{{ $errorMessage("IdentityNo", ValidationErrors) }}</span>
            </div>
            <el-button class="primary-btn save-button" @click="updateInvoiceSettings()" :loading="loading" :disabled="loading"><i class="icon-refresh" v-if="!loading"></i>{{ $localization("web-client-form-save") }}</el-button>
          </div>
        </div>
        <div class="dialog-right" v-if="activeTab == 2">
          <div class="container">
            <div class="title2">{{ $localization("web-client-form-my-saved-cards") }}</div>
            <div class="empty" v-if="user.PaymentMethods.length == 0" style="justify-content: center">
              <div class="lottie-container appointment">
                <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
                <div class="no-data-text">{{ $localization("web-client-form-no-payment-method-saved") }}</div>
              </div>
            </div>
            <ul class="credit-card-list">
              <li class="item" v-for="(card, index) in user.PaymentMethods" :key="index">
                <div class="icon" @click="deletePaymentMethod(card.Id)">
                  <i class="icon-cross"></i>
                </div>
                <div class="card-info">
                  <div class="title">
                    {{ card.Title }}
                  </div>
                  <div class="card-bin">
                    <div class="period-container">
                      <div class="period">
                        {{ card.Bin.slice(0, 4) }}
                      </div>
                      <div class="period">
                        {{ card.Bin.slice(4, 6) }}
                        <div class="icon-period" v-for="i in 2" :key="i"></div>
                      </div>
                      <div class="period">
                        <div class="icon-period" v-for="i in 4" :key="i"></div>
                      </div>
                      <div class="period">
                        <div class="icon-period" v-for="i in 4" :key="i"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-img">
                  <img src="/img/cardassocation/mastercard.svg" v-if="card.CardAssociation == 'MASTER_CARD'" alt="" />
                  <img src="/img/cardassocation/visa.svg" v-if="card.CardAssociation == 'VISA'" alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="childrenSettings" :width="screenWidth > 991 ? '40%' : '90%'" class="children-settings-dialog" :close-on-click-modal="false">
      <div class="dialog">
        <div class="close" @click="childrenSettings = false">
          <i class="icon-close"></i>
        </div>
        <div class="dialog-children">
          <div class="container">
            <div class="title2">{{ $localization("web-client-add-child") }}</div>
            <div class="input-container">
              <div>{{ $localization("web-client-child-name") }}</div>
              <el-input class="login-input-email" v-model="childForm.FirstName" placeholder="..."></el-input>
              <span class="error">{{ $errorMessage("FirstName", validation) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization("web-client-child-surname") }}</div>
              <el-input class="login-input-email" v-model="childForm.LastName" placeholder="..."></el-input>
              <span class="error">{{ $errorMessage("LastName", validation) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization("web-register-nationality") }}</div>
              <el-select
                class="login-input-email"
                v-if="countryList.length > 0"
                v-model="childForm.CitizenshipId"
                style="width: 100%"
                :placeholder="$localization('web-register-nationality')"
                filterable
                clearable
                @clear="childForm.CitizenshipId = null"
                >
                <el-option v-for="item in countryList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
              </el-select>
              <span class="error">{{  $errorMessage("CitizenshipId", validation) }}</span>
            </div>
            <div class="input-container">
              <div>{{ $localization("web-client-child-identity") }}</div>
              <el-input class="login-input-email tc" maxlength="11" v-mask="mask.Identity" :placeholder="$localization('web-client-child-identitynumber')" v-model="childForm.IdentityNumber"></el-input>
              <span class="error">{{ $errorMessage("IdentityNumber", validation) }}</span>
            </div>

            <div class="input input-container">
              <div class="text">{{ $localization("web-client-child-birthdate") }}</div>
              <el-date-picker class="login-input-email" style="width: 100%" v-model="childForm.BirthDate" format="dd.MM.yyyy" type="date" :placeholder="$localization('web-client-form-please-select')" prefix-icon="none"> </el-date-picker>
              <span class="error">{{ $errorMessage("BirthDate", validation) }}</span>
            </div>
            <div class="input-container">
              <div>{{ $localization("web-client-child-gender") }}</div>
              <el-select class="login-input-email" v-model="childForm.Gender" :placeholder="$localization('web-client-form-please-select')" v-if="genderEnums != null">
                <el-option v-for="(item, index) in genderEnums.Enums" :key="index" :label="item.DisplayName" :value="item.Key"> </el-option>
              </el-select>
              <span class="error">{{ $errorMessage("Gender", validation) }}</span>
            </div>

       
            <el-button class="primary-btn save-button" @click="addUpdateChild()"><i class="icon-refresh"></i>{{ $localization("web-client-form-save") }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <div class="header-child">
      <div class="head-title">{{ $localization("web-client-my-children") }}</div>
      <div class="add-button" @click="openChildrenDialog(null)">
        <el-button class="btn-shadow"><i></i></el-button>
      </div>
    </div>
    <el-table :header-cell-class-name="'table-header'" :data="childrenList" style="min-width: 340px">
      <el-table-column sortable prop="name" :label="$localization('web-client-child-name-upper')" :min-width="200" >
        <template slot-scope="scope">
          <div class="user-info">
            <div class="user-avatar">
              <div class="short">{{ scope.row.FirstName[0] + scope.row.LastName[0] }}</div>
            </div>
            <div class="user-name">
              <span>{{ scope.row.FirstName + " " + scope.row.LastName }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable prop="BirthDate" :label="$localization('web-client-child-birthdate-upper')" :min-width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.BirthDate">{{ $moment(scope.row.BirthDate).format("DD-MM-YYYY") }}</span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column sortable :label="$localization('web-client-child-gender-upper')" :min-width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.Gender"> {{ getEnums.find((x) => x.Title == "Gender").Enums.find((x) => x.Key == scope.row.Gender).DisplayName }} </span>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="200">
        <template slot-scope="scope">
          <el-dropdown>
            <span class="el-dropdown-link"> <i class="icon-dots"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="openChildrenDialog(scope.row)" style="display: block; width: 100%">{{ $localization("web-client-see-details") }}</span>
              </el-dropdown-item>
              <el-dropdown-item divided
                ><span class="tex-danger" style="display: block" @click="deleteChild(scope.row.ID)">{{ $localization("web-client-delete") }}</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <template slot="empty">
        <div class="lottie-container">
          <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
          <div class="no-data-text">{{ $localization("web-client-no-data-found") }}</div>
        </div>
      </template>
    </el-table>

    <!-- 3D Secure Dialog -->
    <el-dialog :visible.sync="show3DDialog" :width="screenWidth > 991 ? '40%' : '90%'" class="threed-dialog" :close-on-click-modal="false" :show-close="false">
      <div class="dialog">
        <div class="iframe-container" v-html="threedHtml"></div>
      </div>
    </el-dialog>

    <!-- Kredi Kartı Ekleme Dialog -->
    <el-dialog :visible.sync="showCardDialog" :width="screenWidth > 991 ? '40%' : '90%'" class="card-settings-dialog" :close-on-click-modal="false">
      <div class="dialog">
        <div class="close" @click="showCardDialog = false">
          <i class="icon-close"></i>
        </div>
        <div class="dialog-card">
          <div class="container">
            <div class="title2">{{ $localization("web-client-add-card") }}</div>
            
            <div class="input-container">
              <div>{{ $localization("web-interview-cardholder") }}</div>
              <el-input class="primary-input" v-model="form.CardHolder" placeholder="..."></el-input>
              <span class="error">{{ $errorMessage("cardHolder", ValidationErrors) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization("web-interview-cardno") }}</div>
              <el-input class="primary-input" v-model="form.CardNO" v-mask="'#### #### #### ####'" placeholder="..."></el-input>
              <span class="error">{{ $errorMessage("cardNo", ValidationErrors) }}</span>
            </div>

            <div class="card-details">
              <div class="input-container">
                <div>{{ $localization("web-interview-expire-date") }}</div>
                <el-input class="primary-input" v-model="form.ExpireDate" v-mask="'##/##'" placeholder="AA/YY"></el-input>
                <span class="error" v-if="ValidationErrors.some(x => x.Key == 'expireYear' || x.Key == 'expireMonth')">{{ $errorMessage("expireYear", ValidationErrors) }}</span>
              </div>

              <div class="input-container">
                <div>CVV</div>
                <el-input class="primary-input" v-model="form.Cvc" v-mask="'###'" placeholder="..."></el-input>
                <span class="error">{{ $errorMessage("cvc", ValidationErrors) }}</span>
              </div>
            </div>

            <el-button class="primary-btn save-button" @click="saveCard()">
              <i class="icon-refresh"></i>{{ $localization("web-client-form-save") }}
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <div id="hidden" style="display: none;"></div>
  </div>
</template>

<script>
import CreditCardVue from "../../components/CreditCard.vue";
import VerifyDialog from "../../components/VerifyDialog.vue";
import * as ActionNames from "@/store/action-names";
export default {
  components: {
    CreditCardVue,
    VerifyDialog,
  },
  data() {
    return {
      mask: {
        Identity: "###########",
        PhoneNumber: "+############",
      },
      phoneFormatter: null,
      loading: false,
      ValidationErrors: [],
      screenWidth: 0,
      activeTab: 0,
      userForm: JSON.parse(JSON.stringify(this.$store.getters.getUser)),
      invoiceForm: JSON.parse(JSON.stringify(this.$store.getters.getUser.InvoiceAddress)),
      profileSettingsForm: {
        cardOwner: "",
        cardNumber: "",
        expirationDate: "",
        cvvCode: "",
      },
      value: "",
      profileSettings: false,
      childrenSettings: false,
      showCardDialog: false,
      form: {
        CardNO: "",
        CardHolder: "",
        ExpireDate: "",
        Cvc: "",
      },
      pastAppointment: [],
      pastTotalCount: 0,
      childForm: {
        ParentId: null,
        FirstName: null,
        LastName: null,
        Gender: null,
        Title: null,
        ID: null,
        BirthDate: null,
        IdentityNumber: null,
        CitizenshipId: null,
      },
      long: null,
      lat: null,
      adress: null,
      showVerifyDialog: false,
      expireMinute: 0,
      isEmailValidation: true,
      tempUser: null,
      show3DDialog: false,
      threedHtml: "",
    };
  },
  methods: {
    async deleteAccount() {
      this.$confirm(this.$localization("web-client-are-you-sure-delete-account"), this.$localization("web-client-warning"), {
        confirmButtonText: this.$localization("web-client-yes"),
        cancelButtonText: this.$localization("web-client-no"),
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
        type: "warning",
      })
        .then(async () => {
          console.log("sil");
          var res = await this.$client.post("/Account/Delete", {});
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            await this.$client.post("/Account/SignOut");
            localStorage.removeItem("token");
            this.$store.state.auth.user = null;
            this.$router.push({ name: "Login" });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$localization("web-client-process-cancelled"),
          });
        });
    },
    findLocation() {
      var self = this;
      navigator.geolocation.getCurrentPosition(function (item) {
        var url = "https://nominatim.openstreetmap.org/reverse?format=xml&lat=" + item.coords.latitude + "&lon=" + item.coords.longitude + "&zoom=18&addressdetails=1";
        window.jQuery.get(url, function (data) {
          self.userForm.Address = data.getElementsByTagName("province")[0].childNodes[0].nodeValue + " / " + data.getElementsByTagName("town")[0].childNodes[0].nodeValue;
          self.userForm.Latitude = item.coords.latitude.toString();
          self.userForm.Longitude = item.coords.longitude.toString();
        });
      });
    },
    async deleteChild(Id) {
      await this.$store.dispatch("deleteChild", { Id });
    },
    clearForm() {
      this.validation = [];
      this.childForm = {
        ParentId: null,
        FirstName: null,
        LastName: null,
        Gender: null,
        Height: null,
        Weight: null,
        Title: null,
        ID: null,
        BirthDate: null,
        IdentityNumber: null,
        CitizenshipId: null,
      };
    },
    async addUpdateChild() {
      this.$store.commit("setChildrenValidation", []);
      if (this.childForm.IdentityNumber != null) {
        if (this.childForm.IdentityNumber.length != 11) {
          this.$message.error(this.$localization("web-client-identity-number-must-be-11-digits"));
          return;
        }
      } else {
        this.$message.error(this.$localization("web-client-identity-number-required"));
        return;
      }
      if (this.childForm.Weight == "") {
        this.childForm.Weight = null;
      }
      if (this.childForm.Height == "") {
        this.childForm.Height = null;
      }
      var response = await this.$store.dispatch("addUpdateChild", this.childForm);
      if (!response.data.HasError) {
        this.$message.success(response.data.Message);
        this.childForm = {
          ParentId: null,
          FirstName: null,
          LastName: null,
          Gender: null,
          Height: null,
          Weight: null,
          Title: null,
          ID: null,
          BirthDate: null,
          IdentityNumber: null,
          CitizenshipId: null,
        };
        this.childrenSettings = false;
      }
    },
    async openChildrenDialog(item) {
      await this.$store.commit("setChildrenValidation", []);
      if (item != null) {
        this.childForm.ID = item.ID;
        this.childForm.Title = item.Title;
        this.childForm.ParentId = item.ParentId;
        this.childForm.FirstName = item.FirstName;
        this.childForm.LastName = item.LastName;
        this.childForm.Gender = item.Gender;
        this.childForm.Weight = item.Weight;
        this.childForm.Height = item.Height;
        this.childForm.BirthDate = item.BirthDate;
        this.childForm.IdentityNumber = item.IdentityNumber;
        this.childForm.CitizenshipId = item.CitizenshipId;
      } else {
        this.childForm.ID = null;
        this.childForm.Title = null;
        this.childForm.ParentId = null;
        this.childForm.FirstName = null;
        this.childForm.LastName = null;
        this.childForm.Gender = null;
        this.childForm.Weight = null;
        this.childForm.Height = null;
        this.childForm.BirthDate = null;
        this.childForm.IdentityNumber = null;
        this.childForm.CitizenshipId = null;
      }
      this.childrenSettings = true;
    },

    async updateProfileSettings() {
      this.loading = true;
      this.ValidationErrors = [];

      if (this.phoneFormatter != null) {
        if (this.phoneFormatter.isValid != false) {
          this.userForm.PhoneNumber = this.phoneFormatter.formatInternational.replaceAll(" ", "");
        } else {
          this.loading = false;
          //!!! FC 13/03/24++ Telefon geçersiz ise mesajı ver ve çık
          this.$message.warning(this.$localization("web-client-phone-number-must-be-valid"));
          return;
        }
      } else {
        // !!! FC 13/04/24++vTelefon ile giriş yapılıyorsa hata ver
        this.userForm.PhoneNumber = null; // aşağıdaki atamada hata vermesin
        if (this.userForm.RegisterType == null || this.userForm.RegisterType == 0) {
          this.loading = false;

          this.$message.warning(this.$localization("web-client-phone-number-must-be-valid"));
          return;
        }
      }
      //!!! FC 13/03/24 ++ PhoneNumber Null kontrolu yapıldı
      //this.userForm.PhoneNumber !== null ? ()
      //!!! FC 10/05/24++ Tarihi duzenliyoruz cunku saate gore gun atlatıyor
      var tmpdate = this.userForm.BirthDate;
      if (this.user.IsMernisVerified == null || this.user.IsMernisVerified == false) {
        if (tmpdate != null) {
          tmpdate = this.$moment(tmpdate).utc(true); // FC Saati koruyarak utc ye ceviriyoruz (3 saat geri almıyor)
        }
      }
      var updateObj = {
        FirstName: this.userForm.FirstName,
        LastName: this.userForm.LastName,
        CitizenshipId: this.userForm.CitizenshipId,
        IdentityNumber: this.userForm.IdentityNumber,
        PassportNumber: this.userForm.PassportNumber,
        CityId: this.userForm.City.ID,
        DistrictId: this.userForm.District.ID,
        PhoneNumber: this.userForm.PhoneNumber !== null ? (this.userForm.PhoneNumber.replaceAll("+" + this.phoneFormatter.countryCallingCode, "").trim().length === 0 ? null : this.userForm.PhoneNumber) : null,
        Email: this.userForm.Email !== null ? (this.userForm.Email.trim().length === 0 ? null : this.userForm.Email) : null,
        BirthDate: tmpdate,
        Gender: this.userForm.Gender,
        Address: this.userForm.Address,
        Latitude: this.userForm.Latitude,
        Longitude: this.userForm.Longitude,
        Weight: this.userForm.Weight,
        Height: this.userForm.Height,
      };
      if (this.userForm.Weight == "") {
        updateObj.Weight = null;
      }
      if (this.userForm.Height == "") {
        updateObj.Height = null;
      }
      var res = await this.$client.post("/Account/UpdateUserPersonalInfo", updateObj);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        if (res.data.Data.OTPAttemptType !== null && res.data.Data.OTPAttemptType !== 0) {
          this.showVerifyDialog = true;
          this.tempUser = updateObj;
          this.expireMinute = res.data.Data.ExpireMinute;
          this.isEmailValidation = res.data.Data.OTPAttemptType === 4; //4 email  3 phone
        }

        this.$message.success(res.data.Message);
        await this.$store.dispatch("getUser");
        this.close();
      }
      this.loading = false;
    },

    async updateInvoiceSettings() {
      this.ValidationErrors = [];
      var updateObj = {
        Country: this.invoiceForm.Country,
        City: this.invoiceForm.City,
        District: this.invoiceForm.District,
        Address: this.invoiceForm.Address,
        ZipCode: this.invoiceForm.ZipCode,
        PhoneNumber: this.invoiceForm.PhoneNumber,
        IdentityNo: this.invoiceForm.IdentityNo,
      };
      var res = await this.$client.post("/Account/UpdateUserInvoiceInfo", updateObj);
      this.ValidationErrors = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        await this.$store.dispatch("getUser");
        this.$message.success(res.data.Message);
        this.close();
      }
    },
    close() {
      this.profileSettings = false;
    },

    openEditDialog(tabIndex) {
      this.activeTab = tabIndex;
      this.profileSettings = false;
      this.invoiceForm = JSON.parse(JSON.stringify(this.$store.getters.getUser.InvoiceAddress));
      this.userForm = JSON.parse(JSON.stringify(this.$store.getters.getUser));
      this.profileSettings = true;

      if (this.userForm.City == null) {
        this.userForm.City = { ID: null };
      }
      if (this.userForm.District == null) {
        this.userForm.District = { ID: null };
      }
    },
    goDetails(id) {
      this.$router.push("/kullanici/gorusme-detay/" + id);
    },
    async uploadFile() {
      let file = this.$refs.file.files[0];
      var fd = new FormData();
      fd.append("file", file);
      var res = await this.$client.post("/Account/UploadFile?type=3", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!res.data.HasError) {
        var uploadImage = await this.$client.post("/Account/UpdateProfilePicture", { image: res.data.Item.Url });
        if (uploadImage.data.HasError) {
          this.$message.error(uploadImage.data.Message);
        } else {
          this.$message.success(uploadImage.data.Message);
          this.user.Image = res.data.Item.Url;
        }
      } else {
        this.$message.error(res.data.Message);
      }
      this.$refs.file.value = null;
    },
    uploadFiles() {
      this.$refs.file.click();
    },
    async getPastAppointment() {
      var res = await this.$client.post("/Appointment/List", {
        PageSize: 5,
        Page: 1,
        Status: 3,
        PastAppointments: true,
        SearchTerm: null,
      });

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.pastAppointment = res.data.Data.Appointments;
        this.pastTotalCount = res.data.Data.TotalCount;
      }
    },
    async deletePaymentMethod(id) {
      this.$confirm(this.$localization("web-client-are-you-sure-to-delete-card"), this.$localization("web-client-warning"), {
        confirmButtonText: this.$localization("web-client-yes-delete"),
        cancelButtonText: this.$localization("web-client-cancel"),
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Account/DeletePaymentMethod", {
            ID: id,
          });
          if (!res.data.HasError) {
            this.$message.success(res.data.Message);
            this.user.PaymentMethods = this.user.PaymentMethods.filter((x) => x.Id != id);
          } else {
            this.$message.error(res.data.Message);
          }
        })
        .catch(() => {});
    },
    async getDistrictList(id) {
      await this.$store.dispatch(ActionNames.GET_DISTRICT_LIST, id);
    },
    async saveCard() {
      // Expire date'i ay ve yıl olarak ayırma
      const [expireMonth, expireYear] = this.form.ExpireDate.split('/');
      
      const payload = {
        CardNo: this.form.CardNO.replaceAll(' ', ''),
        CardHolder: this.form.CardHolder,
        ExpireYear: expireYear,
        ExpireMonth: expireMonth,
        Cvc: this.form.Cvc,
        SaveCard: true,
        AppointmentRequestFrom: 0, 
        Permission: true
      };

      var res = await this.$client.post("/Account/SavePaymentMethodWeb", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        if (res.data.Data.ThreeD) {
          if (res.data.Data.goToThreedHtmlPage) {
            window.location.href = res.data.Data.ThreedHtml;
          } else {
            window.jQuery("#hidden").html(res.data.Data.ThreedHtml);
            window.jQuery("#hidden").find("form").submit();
          }
          this.showCardDialog = false;
        } else {
          this.$message.success(res.data.Message);
          await this.$store.dispatch("getUser");
          this.showCardDialog = false;
          // Form'u temizle
          this.form = {
            CardNO: "",
            CardHolder: "",
            ExpireDate: "",
            Cvc: "",
          };
        }
      }
    },
  },
  async beforeMount() {
    if (this.userForm.City == null) {
      this.userForm.City = { ID: null };
    }
    if (this.userForm.District == null) {
      this.userForm.District = { ID: null };
    }

    await this.getPastAppointment();
    await this.$store.dispatch("getChildrenList", this.filter);
    window.jQuery(document).ready(function () {
      window.jQuery(".profile-slider").owlCarousel({
        items: 1,
        margin: 30,
      });
    });
  },
  computed: {
    countryList() {
      return this.$store.getters.getCountryList;
    },
    cityList() {
      return this.$store.getters.getCityList;
    },
    districtList() {
      return this.$store.getters.getDistrictList;
    },
    genderEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Gender");
    },
    user() {
      return this.$store.getters.getUser;
    },
    filter() {
      return this.$store.getters.getChildrenFilter;
    },
    childrenList() {
      return this.$store.getters.getChildrenList;
    },
    validation() {
      return this.$store.getters.getChildrenValidation;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.pageview({ page_path: this.$route.path, page_title: "Profile | Elra Sağlık" });
    }
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    if (this.userForm.City != null) {
      this.getDistrictList(this.userForm.City.ID);
    }

    // URL'den addPaymentMethod parametresini kontrol et
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('addPaymentMethod') === 'success') {
      this.$message.success("Kart başarıyla eklendi");
      // Parametreyi URL'den temizle
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      // Kullanıcı bilgilerini güncelle
      this.$store.dispatch("getUser");
    }
  },
  watch: {
    show3DDialog(newVal) {
      if (newVal) {
        // Dialog açıldığında form'u otomatik submit et
        this.$nextTick(() => {
          // Önce form elementini oluştur
          const container = document.createElement('div');
          container.style.display = 'none';
          container.innerHTML = this.threedHtml;
          document.body.appendChild(container);

          // Form verilerini doldur ve submit et
          const form = container.querySelector('#webform0');
          if (form) {
            // Browser bilgilerini doldur
            const populateData = () => {
              if (form.querySelector('[name="TDS2_Navigator_language"]')) {
                form.querySelector('[name="TDS2_Navigator_language"]').value = navigator.language || '';
              }
              if (form.querySelector('[name="TDS2_Navigator_jsEnabled"]')) {
                form.querySelector('[name="TDS2_Navigator_jsEnabled"]').value = 'true';
              }
              if (form.querySelector('[name="TDS2_Navigator_javaEnabled"]')) {
                form.querySelector('[name="TDS2_Navigator_javaEnabled"]').value = navigator.javaEnabled();
              }
              if (form.querySelector('[name="TDS2_Screen_colorDepth"]')) {
                form.querySelector('[name="TDS2_Screen_colorDepth"]').value = screen.colorDepth;
              }
              if (form.querySelector('[name="TDS2_Screen_height"]')) {
                form.querySelector('[name="TDS2_Screen_height"]').value = screen.height;
              }
              if (form.querySelector('[name="TDS2_Screen_width"]')) {
                form.querySelector('[name="TDS2_Screen_width"]').value = screen.width;
              }
              if (form.querySelector('[name="TDS2_Screen_PixelDepth"]')) {
                form.querySelector('[name="TDS2_Screen_PixelDepth"]').value = screen.pixelDepth;
              }
              if (form.querySelector('[name="TDS2_TimezoneOffset"]')) {
                form.querySelector('[name="TDS2_TimezoneOffset"]').value = new Date().getTimezoneOffset();
              }
            };

            populateData();
            form.submit();
          }
        });
      }
    }
  },
};
</script>

<style lang="less">
#client-profile {
  .location {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .p-head {
    margin-top: 0;
  }

  padding: 42px 30px;

  @media screen and (max-width: 550px) {
    padding: 20px 10px;
  }

  .header-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    min-width: 340px;
    .head-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 23px;
      color: #44566c;
    }

    .add-button {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        height: 40px;
        width: 40px;
        background-color: #234a98;
        border: none;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #234a98;
          border-color: none;
          color: white;
        }

        i {
          width: 24px;
          height: 24px;
          display: block;
          mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
          -webkit-mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
          background-color: white;
        }
      }
    }
  }

  .user-info {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .user-avatar {
      flex-shrink: 0;
      width: 30px;
      height: 30px;

      .short {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #234a98;
        height: 100%;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }

  .el-select__caret {
    // background-color: #f8fafb;
    color: #b3c0ce;
  }

  p {
    font-weight: 700;
    font-size: 30px;
    color: #44566c;
  }

  .el-select {
    width: 100%;
  }

  .main {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 991px) {
      flex-direction: column;

      .profile,
      .middle,
      .end {
        width: 100% !important;
      }
    }

    .profile-name {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #44566c;
      margin-top: 0;
      text-align: center;
    }

    .profile {
      width: 24.5%;
      // height: 566px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background-color: #eff4f8;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      min-width: 240px;
    }

    .profile-top {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }

    .profile-bottom {
      background-color: #f8fafb;
      padding: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .item {
        display: flex;
        position: relative !important;
        flex-direction: row;
        justify-content: flex-start;
        gap: 0.4rem;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;

        .i {
          position: absolute !important;
        }

        & > :last-child {
          min-width: 150px;
        }
      }
    }

    .profile-photo {
      width: 220px;
      height: 220px;
      border-radius: 40px;
      overflow: hidden;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      img {
        width: 100%;
      }
    }

    .edit {
      span {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 0.4rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
        border: none;
      }
    }
  }

  .middle {
    width: 37.75%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 240px;
    .address {
      background-color: #eff4f8;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      padding: 30px;
      gap: 1rem;

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #44566c;
      }

      & > .info {
        display: flex;
        justify-content: space-between;
        gap: 60px;

        .data1 {
          font-weight: 400;
          min-width: 40px;
          font-size: 15px;
          color: #8697a8;
          align-items: flex-end;
        }

        .data2 {
          color: #44566c;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
        }

        &.address-info {
          & > .data2 {
            word-break: break-word;
            text-align: right;
          }
        }
      }
    }

    .icon-task {
      width: 22px;
      height: 22px;
      background-color: #44566c;
      float: right;
    }

    .last-conversations {
      width: 100%;
      background-color: #eff4f8;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 15px;
      padding: 25px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        a {
          font-weight: 400;
          font-size: 15px;
          text-decoration: none;
          color: #234a98;
        }

        div {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #44566c;
        }
      }

      .profile-slider {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .owl-carousel {
          overflow: hidden;
        }
      }
    }

    .dr-card {
      overflow: hidden;
      background-color: #f8fafb;
      border-radius: 15px;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      align-items: center;
    }

    .dr-information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 25px;
      width: 100%;

      .date {
        margin-top: 5px;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;

        color: #44566c;
        margin-bottom: 30px;
      }

      .detail {
        width: 137px;
        height: 40px;
      }

      i {
        float: left;
      }
    }

    .dr-photo {
      flex-shrink: 0;
      background-color: #8697a8;
      width: 148px;
      height: 183px;
      border-radius: 15px 0 0 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .end {
    width: 37.75%;
    display: flex;
    gap: 30px;
    min-width: 240px;

    .payment {
      height: 100%;
      width: 100%;
      background-color: #eff4f8;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow: hidden;

      .empty {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      .pay {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #44566c;
        padding: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .add-button {
          button {
            height: 40px;
            width: 40px;
            background-color: #234a98;
            border: none;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:hover {
              background-color: #234a98;
              border-color: none;
              color: white;
            }

            i {
              width: 24px;
              height: 24px;
              display: block;
              mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
              -webkit-mask: url("../../assets/img/icons/Plus.svg") no-repeat center;
              background-color: white;
            }
          }
        }
      }

      .payment-top {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .payment-bottom {
        background-color: #f8fafb;
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      .data1 {
        font-weight: 400;
        font-size: 15px;
        color: #8697a8;
      }

      .data2 {
        color: #44566c;
        font-weight: 500;
        font-size: 15px;
        float: right;
      }
    }
  }

  .profile-settings-dialog {
    .el-dialog {
      min-width: 600px;

      @media screen and (max-width: 550px) {
        min-width: 340px;
      }

      border-radius: 1rem;
      overflow: hidden;

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: 0;

        .dialog {
          min-width: 600px;
          position: relative;

          @media screen and (max-width: 670px) {
            min-width: 340px;
          }

          .dialog-right {
            .container {
              padding-left: 20px;
              padding-right: 20px;

              @media screen and (max-width: 670px) {
                padding-left: 0;
                padding-right: 0;
              }

              .input-container {
                margin-bottom: 15px;

                .el-date-editor {
                  .el-input__inner {
                    padding-left: 15px;
                  }
                }
              }
            }
          }

          .close {
            position: absolute;
            right: 1rem;
            cursor: pointer !important;
            top: 1rem;
            width: 28px;
            height: 28px;
            padding: 3px;
            border-radius: 50%;
            background-color: #eaedf0;

            .icon-close {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .children-settings-dialog {
    .el-dialog {
      min-width: 600px;
      border-radius: 1rem;
      overflow: hidden;

      @media screen and (max-width: 670px) {
        min-width: 340px;
      }

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: 0;

        .dialog {
          min-width: 600px;
          position: relative;

          @media screen and (max-width: 670px) {
            min-width: 340px;
          }

          .dialog-children {
            padding: 14px;
            width: 100%;
            padding-bottom: 20px;

            .container {
              padding-left: 20px;
              padding-right: 20px;

              .input {
                .el-date-editor {
                  .el-input__inner {
                    padding-left: 15px;
                  }
                }
              }
            }
          }
        }

          .close {
            position: absolute;
            right: 1rem;
            cursor: pointer !important;
            top: 1rem;
            width: 28px;
            height: 28px;
            padding: 3px;
            border-radius: 50%;
            background-color: #eaedf0;

            .icon-close {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .dialog {
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;

      .dialog-left,
      .dialog-right {
        width: 100% !important;

        .container {
          .el-select {
            .el-input {
              .el-input__suffix {
                .el-input__suffix-inner {
                  .el-select__caret {
                    background: transparent !important;
                  }
                }
              }
            }
          }
        }
      }

      .dialog-left {
        .dialog-left-bottom {
          ul {
            margin: 0;
            flex-direction: row;

            li {
              flex-direction: column;
              height: auto;
            }
          }
        }
      }
    }

    .dialog-left-bottom {
      background: #f8fafb;
      padding-left: 8px;

      ul {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0;
      }

      li {
        cursor: pointer;
        display: flex;
        list-style: none;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 100%;

        @media screen and (max-width: 550px) {
          padding: 5px;
          text-align: center;
        }

        &.active {
          background-color: #eff4f8;
          border-radius: 11px 0 0 11px;
        }

        &.delete-account {
          background-color: #f8fafb;

          & > div {
            color: rgba(176, 169, 169, 0.744);
          }

          & > i {
            background-color: rgba(176, 169, 169, 0.744);
          }
        }

        height: 56px;

        div {
          font-weight: 500;
          font-size: 17px;
          color: #44566c;
        }
      }
    }

    .dialog-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      background-color: #f8fafb;
    }

    .dialog-left-top {
      padding-top: 2rem;
      display: flex;
      align-self: center;
      margin-bottom: 55px;

      .image {
        cursor: pointer;
        width: 170px;
        background-position: center;
        background-size: cover !important;
        height: 170px;
        background: #44566c;
        opacity: 0.6;
        border-radius: 40px;
        box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
        display: flex;
        flex-direction: column;
        justify-content: center;

        div,
        i {
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          align-self: center;
        }
      }
    }

    .dialog-right {
      padding: 1rem;
      width: 60%;
      display: flex;
      flex-direction: column;

      & > .container {
        .credit-card-list {
          li {
            cursor: unset;

            .icon {
              cursor: pointer;
            }
          }
        }

        width: 100%;

        .input-container {
          display: flex;
          gap: 5px;
          flex-direction: column;
          margin-bottom: 15px;
        }

        .input-container-2 {
          width: 100%;
          display: flex;
          margin-bottom: 15px;
          flex-direction: column;
          gap: 5px;

          input {
            border-radius: 8px !important;
          }

          .phone {
            padding: 2px;
            width: 100%;

            .input-tel {
              width: 100%;
            }

            .vue-phone-number-input {
              width: 100%;
              gap: 20px;

              .select-country-container {
                .country-selector {
                  &:hover {
                    z-index: unset !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

.card-settings-dialog {
  .el-dialog {
    min-width: 600px;
    border-radius: 1rem;
    overflow: hidden;

    @media screen and (max-width: 670px) {
      min-width: 340px;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;

      .dialog {
        min-width: 600px;
        position: relative;

        @media screen and (max-width: 670px) {
          min-width: 340px;
        }

        .dialog-card {
          padding: 14px;
          width: 100%;
          padding-bottom: 20px;

          .container {
            padding-left: 20px;
            padding-right: 20px;

            .card-details {
              display: flex;
              gap: 20px;

              .input-container {
                flex: 1;
              }
            }
          }
        }

        .close {
          position: absolute;
          right: 1rem;
          cursor: pointer !important;
          top: 1rem;
          width: 28px;
          height: 28px;
          padding: 3px;
          border-radius: 50%;
          background-color: #eaedf0;

          .icon-close {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.threed-dialog {
  .el-dialog {
    min-width: 600px;
    border-radius: 1rem;
    overflow: hidden;

    @media screen and (max-width: 670px) {
      min-width: 340px;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;

      .dialog {
        min-width: 600px;
        position: relative;

        @media screen and (max-width: 670px) {
          min-width: 340px;
        }

        .iframe-container {
          width: 100%;
          height: 600px;
          overflow: hidden;

          iframe {
            width: 100%;
            height: 100%;
            border: none;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="elramed-login-screen">
    <div class="background"></div>
    <div class="login-container">
      <div class="login-top">
        <div class="right">
          <div class="logo-header">
            <img src="../assets/img/logo-white.svg" alt="" />
            <div class="logo-text">Anında Online Doktor</div>
          </div>
          <div class="login" @keyup.enter="otpScreen()">
            <div class="title" v-if="otp == true">{{ $localization("web-login-sign-in") }}</div>
            <!-- <div class="description" v-if="otp == false">{{ $localization("web-login-you-can-enter-you-phone") }}</div> -->
            <div class="description" v-if="otp == true">{{ $localization("web-login-enter-otp-code") }}</div>
            <div class="login-section" v-if="otp == false">
              <!-- handle login form phone or email but can view one input -->
              <div class="login-input">
                <vue-phone-number-input
                  v-if="!isEmailLogin"
                  v-model="PhoneNumberText"
                  @update="phoneFormatter = $event"
                  @phone-number-focused="changeLoginMethod(false)"
                  :no-example="true"
                  :no-flags="true"
                  :translations="{
                    countrySelectorLabel: $localization('web-login-country-code'),
                    countrySelectorError: $localization('web-login-select-country-code'),
                    phoneNumberLabel: $localization('web-login-phone-number'),
                    example: $localization('web-login-example'),
                  }"
                  default-country-code="TR"
                  class="login-input-phone no-width" />
                <el-input type="text" v-else placeholder="E-Posta" class="login-input-email" v-model="email" />
              </div>
              <el-button class="primary-btn fullWidth" v-if="!otp" v-on:click="otpScreen()" :loading="loading" :disabled="loading">{{ $localization("web-login-sign-in") }}</el-button>
              <el-button class="primary-btn fullWidth no-bg" v-if="!otp" v-on:click="changeLoginMethod(!isEmailLogin)">{{ isEmailLogin ? "Telefon Numarası ile giriş yapın" : "E-Posta ile giriş yapın" }}</el-button>
              <el-divider>Henüz kayıt olmadınız mı?</el-divider>
            </div>
            <!-- <div class="input-container" v-if="otp == false">
              <div class="radio-group"> -->
            <!-- <form>
                  <label class="radio-row">
                    <input class="radio-button" type="radio" value="false" @change="changeLoginMethod(false)" v-model="isEmailLogin" />
                    <span class="radio-item">
                      <div class="phone">
                        <vue-phone-number-input
                          v-model="PhoneNumberText"
                          @update="phoneFormatter = $event"
                          @phone-number-focused="changeLoginMethod(false)"
                          :no-example="true"
                          :translations="{
                            countrySelectorLabel: $localization('web-login-country-code'),
                            countrySelectorError: $localization('web-login-select-country-code'),
                            phoneNumberLabel: $localization('web-login-phone-number'),
                            example: $localization('web-login-example'),
                          }"
                          default-country-code="TR"
                          class="primary-input input-bg-white" />
                        <span v-if="$errorMessage('PhoneNumber', ValidationErrors) != null" class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
                      </div>
                    </span>
                  </label>
                  <label class="radio-row">
                    <input class="radio-button" type="radio" value="true" @change="changeLoginMethod(true)" v-model="isEmailLogin" />
                    <span class="radio-item">
                      <div class="phone">
                        <vue-input-ui @focus="changeLoginMethod(true)" :value="email" v-model="email" :label="$localization('web-client-form-email')" autocomplete="off" class="primary-input input-bg-white" />
                        <span v-if="$errorMessage('Email', ValidationErrors) != null" class="error">{{ $errorMessage("Email", ValidationErrors) }}</span>
                      </div>
                    </span>
                  </label>
                </form> -->
            <!-- </div>
            </div> -->
            <div class="otp-wrapper" v-else>
              <div class="otps" @keydown="handleKeydown">
                <el-input ref="otp1" placeholder="" v-mask="'#'" class="otp" v-model="otp1" maxlength="1"></el-input>
                <el-input ref="otp2" placeholder="" v-mask="'#'" class="otp" v-model="otp2" maxlength="1"></el-input>
                <el-input ref="otp3" placeholder="" v-mask="'#'" class="otp" v-model="otp3" maxlength="1"></el-input>
                <el-input ref="otp4" placeholder="" v-mask="'#'" class="otp" v-model="otp4" maxlength="1"></el-input>
                <el-input ref="otp5" placeholder="" v-mask="'#'" class="otp" v-model="otp5" maxlength="1"></el-input>
                <el-input ref="otp6" placeholder="" v-mask="'#'" class="otp" v-model="otp6" maxlength="1"></el-input>
              </div>
              <div class="timer">
                <vue-countdown-timer @end_callback="endCallback" :end-text="'Süre Doldu!'" :start-time="new Date().getTime()" :end-time="new Date().getTime() + otpTimerMilisecond" :interval="1000">
                  <template slot="countdown" slot-scope="scope">
                    <div class="otp-timer">
                      <span>{{ scope.props.minutes }}:</span>
                      <span>{{ scope.props.seconds }}</span>
                    </div>
                  </template>
                </vue-countdown-timer>
              </div>
            </div>
            <!-- env'den gelmesi isteniyorsa =>  :sitekey="this.recaptchaKey" elle girilecekse => sitekey="6Le43WYkAAAAAAD1SG1x1Tt_GYJEfVzFk554jd6g" -->
            <vue-recaptcha ref="recaptcha" @verify="verifyMethod" size="invisible" loadRecaptchaScript :sitekey="this.recaptchaKey"></vue-recaptcha>
            <title><%= process.env.VUE_APP_RECAPTCHA %></title>
            <!-- <el-button class="primary-btn fullWidth" v-if="!otp" v-on:click="otpScreen()" :loading="loading" :disabled="loading">{{ $localization("web-login-sign-in") }}</el-button> -->
            <el-button class="primary-btn fullWidth no-gap" v-if="!otp" v-on:click="$router.push('/kayit-ol')">{{ "Kayıt Ol" }}</el-button>
            <!-- <div class="description" v-if="otp == false">{{ $localization("web-login-butons-descriptions", "Üye değilseniz, İlk görüşmeniz için üye olunuz.Kurumsal kodunuz varsa lütfen kurumsal üye olunuz.") }}</div> -->
          </div>
          <!-- <div class="register">
            <el-select class="tc primary-input input-bg-white" v-model="language" style="width: 100%" @change="setLanguage(language)" v-if="!otp">
              <el-option v-for="item in languageList" :key="item.ID" :label="item.Title" :value="item.LanguageCode" style="display: flex; align-items: center">
                <img :src="item.Image" style="height: 20px; width: 30px; margin-right: 10px" />
                {{ item.Title }}
              </el-option>
            </el-select>
          </div> -->
        </div>
      </div>
      <el-button class="back-btn" @click="goWebSite()">{{ $localization("web-login-return-to-website") }}</el-button>

      <!-- 
      <div class="version">
        <p>© 2022 elra.health developed by <a target="_blank" rel="nofollow" href="https://internative.net/">Internative</a> and <a target="_blank" rel="nofollow" href="http://www.gbks.com.tr/">Global Bilgisayar</a> {{this.version}}</p>       
      </div>
    --></div>
  </div>
</template>
<!-- <script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer></script> -->
<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  components: { VueRecaptcha },
  name: "Login",
  data() {
    return {
      languageList: [],
      language: null,
      loading: false,
      ValidationErrors: [],
      email: "",
      phone: "",
      phoneFormatter: null,
      PhoneNumberText: "",
      otp: false,
      otp1: null,
      otp2: null,
      otp3: null,
      otp4: null,
      otp5: null,
      otp6: null,
      otpTimerMilisecond: 60000,
      GRecaptchaResponse: null,
      /*recaptchayı enviormentten çekmek için eklendi*/
      recaptchaKey: process.env.VUE_APP_RECAPTCHA,
      version: process.env.VUE_APP_VERSION,
      isEmailLogin: false,
    };
  },
  async beforeMount() {
    await this.getLanguageList();
    await this.setLanguage(localStorage.getItem("language"));
    this.language = localStorage.getItem("language");
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.pageview({ page_path: this.$route.path, page_title: "Login | Elra Sağlık" });
    }
  },
  methods: {
    /**
     * @param {boolean} emailLogin
     * @returns {void}
     */
    changeLoginMethod(emailLogin) {
      if (emailLogin) {
        this.phone = "";
        this.PhoneNumberText = "";
        this.phoneFormatter = null;
        this.isEmailLogin = true;
      } else {
        this.email = "";
        this.isEmailLogin = false;
      }
    },
    async getLanguageList() {
      var res = await this.$client.post("/Localization/SystemLanguages");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.languageList = res.data.Data.Items;
      }
    },
    async setLanguage(langCode) {
      await this.$store.dispatch("setLanguageLocalisationKey", langCode);
    },
    verifyMethod(payload) {
      this.GRecaptchaResponse = payload;
      // this.otpScreen();
    },
    expiredToken() {
      this.$message.error(this.$localization("web-login-captcha-invalid"));
    },
    goWebSite() {
      //window.open("https://elra.health/", "_blank");
      window.open(process.env.VUE_APP_SITE_URL, "_blank");
    },
    endCallback: function () {
      this.otp = false;
      this.otp1 = null;
      this.otp2 = null;
      this.otp3 = null;
      this.otp4 = null;
      this.otp5 = null;
      this.otpTimerMilisecond = 60000;
    },
    handleKeydown(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (this.otp6 == null || this.otp6 == "") {
          this.$refs.otp5.focus();
        }
        if (this.otp5 == null || this.otp5 == "") {
          this.$refs.otp4.focus();
        }

        if (this.otp4 == null || this.otp4 == "") {
          this.$refs.otp3.focus();
        }

        if (this.otp3 == null || this.otp3 == "") {
          this.$refs.otp2.focus();
        }

        if (this.otp2 == null || this.otp2 == "") {
          this.$refs.otp1.focus();
        }

        if (this.otp1 == null || this.otp1 == "") {
          this.$refs.otp1.focus();
        }
      }
    },
    async otpScreen() {
      this.ValidationErrors = [];
      this.loading = true;

      if (this.isEmailLogin) {
        if (this.email != null && this.email != "") {
          this.phone = null;
          this.phoneFormatter = null;
          this.PhoneNumberText = "";
        } else {
          this.$message.warning(this.$localization("web-login-please-enter-email"));
          this.loading = false;
          return;
        }
      } else {
        if (this.phoneFormatter != null && this.phoneFormatter.isValid != false) {
          this.phone = this.phoneFormatter.formatInternational.replaceAll(" ", "");
          this.email = null;
        } else {
          this.$message.warning(this.$localization("web-login-please-enter-valid-phone"));
          this.loading = false;
          return;
        }
      }

      await this.$refs.recaptcha.execute();
      if (this.GRecaptchaResponse == null) {
        setTimeout(() => this.otpScreen(), 500);
        return;
      }
      var res = await this.$client.post("/Login/SendSignInOtpWeb", { PhoneNumber: this.phone, Email: this.email, GRecaptchaResponse: this.GRecaptchaResponse });
      this.GRecaptchaResponse = null;
      await this.$refs.recaptcha.reset();
      this.ValidationErrors = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$notify({
          title: this.$localization("web-register-warning"),
          message: res.data.Message,
          type: "warning",
          duration: 15000,
        });
      } else {
        this.otpTimerMilisecond = res.data.Data.ExpireMinute * 60 * 1000;
        this.otp = true;
        this.$notify({
          title: "",
          message: res.data.Message,
          type: "success",
          duration: 15000,
        });
        this.$nextTick(() => {
          this.$refs.otp1.focus();
        });
      }
      this.loading = false;
    },
    async verifyOtp() {
      this.ValidationErrors = [];
      //this.otp = false;
      var otpCode = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;
      if (this.phoneFormatter != null && this.phoneFormatter.isValid != false) {
        this.phone = this.phoneFormatter.formatInternational.replaceAll(" ", "");
      } else {
        this.phone = null;
      }
      var res = await this.$client.post("/Login/ValidateSignInOtp", { PhoneNumber: this.phone, Email: this.email, ConfirmationCode: otpCode });
      this.ValidationErrors = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$notify({
          title: "Hata",
          message: res.data.Message,
          type: "error",
        });
        await this.$nextTick(() => {
          this.otp1 = null;
          this.otp2 = null;
          this.otp3 = null;
          this.otp4 = null;
          this.otp5 = null;
          this.otp6 = null;
        });
        this.$nextTick(async () => {
          await this.$refs.otp1.focus();
        });
      } else {
        //await this.$store.commit("setToken", res.data.Data.Token);
        await this.$store.dispatch("setToken", res.data.Data.Token);

        // #region Analyticss
        await this.$store.dispatch("getUser");
        var user = this.$store.getters.getUser;
        if (this.$gtag && user.Role == 1) {
          this.$gtag.event("sign_in", {
            platform: "web",
            user_id: user.ID,
          });
        }
        //#endregion

        this.$nextTick(() => {
          this.$router.push("/").catch(() => {});
        });
      }
    },
    registerButtonClick(IsInstitutionUser) {
      // #region Analyticss
      if (this.$gtag && IsInstitutionUser) {
        this.$gtag.event("institution_register_button_clicked", {
          platform: "web",
        });
      }
      //#endregion
      this.$router.push("/kayit-ol/" + IsInstitutionUser.toString());
    },
  },
  watch: {
    otp1: function () {
      if (this.otp1 != null && this.otp1.length == 1) {
        this.$refs.otp2.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp2: function () {
      if (this.otp2 != null && this.otp2.length == 1) {
        this.$refs.otp3.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp3: function () {
      if (this.otp3 != null && this.otp3.length == 1) {
        this.$refs.otp4.focus();
      } else {
        this.$refs.otp2.focus();
      }
    },
    otp4: function () {
      if (this.otp4 != null && this.otp4.length == 1) {
        this.$refs.otp5.focus();
      } else {
        this.$refs.otp3.focus();
      }
    },
    otp5: function () {
      if (this.otp5 != null && this.otp5.length == 1) {
        this.$refs.otp6.focus();
      } else {
        this.$refs.otp4.focus();
      }
    },
    otp6: function () {
      if (this.otp6 != null && this.otp6.length == 1) {
        this.$nextTick(() => {
          this.verifyOtp();
        });
      } else {
        this.$refs.otp5.focus();
      }
    },
  },
};
</script>

<style lang="less">
.no-gap {
  margin-left: 0 !important;
}
</style>

<script>
import ElraCommentsSlider from "@/components/ElraComments/Slider.vue";

export default {
  name: "ElraComments",
  components: { ElraCommentsSlider }
};
</script>

<template>
  <div class="elra-comments">
    <div class="elra-comments-title">Elra Kullananlar Ne Diyor?</div>
    <elra-comments-slider></elra-comments-slider>
  </div>
</template>

<style lang="less">
.elra-comments {
  display: flex;
  gap: 20px;
  flex-direction: column;
  .elra-comments-title {
    font-weight: bold;
    font-size: 20px;
    color: #121826;
  }
}
</style>
<template>
  <div id="fixed-fields">
    <div class="title">Yorumlar</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Yorumlar</el-breadcrumb-item>
        <el-breadcrumb-item>Kartlar</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content-wrapper">
      <div class="form-section">
        <div class="part">
          <div class="header">Yorumlar</div>

          <div v-for="(item, index) in Comments" :key="index" class="card-container">
            <div class="card-edit-section">
              <div class="top">
                <div class="mid-text">Yorum {{ index + 1 }}</div>
                <div class="delete-icon" @click="deleteItem(index)">
                  <i class="icon-delete-fixed"></i>
                </div>
              </div>

              <el-input type="text" placeholder="Ad..." v-model="Comments[index].FirstName"> </el-input>
              <el-input type="text" placeholder="Soyad..." v-model="Comments[index].LastName"> </el-input>
              <div class="rate-container">
                <span class="rate-label">Puan:</span>
                <el-rate v-model="Comments[index].Rate" :max="5"></el-rate>
              </div>
              <el-input type="textarea" :rows="4" v-model="Comments[index].Desc" placeholder="Açıklama..."> </el-input>
            </div>
          </div>

          <div class="button-group-admin">
            <el-button class="confirmReportAdmin" type="primary" @click="update"><i class="icon-send"></i>Kaydet</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addItem"><i class="icon-plus"></i>Yorum Ekle</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languageList: [],
      ValidationErrors: [],
      Comments: [],
    };
  },
  async beforeMount() {
    await this.getData();
  },
  methods: {
    async getData() {
      var res = await this.$client.post("/AboutUs/GetUserComments");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.Comments = res.data.Data.Items;
      }
    },
    addItem() {
      this.Comments.push({
        ID: null,
        FirstName: null,
        LastName: null,
        Desc: null,
        Rate: 0,
        Title: null,
        CreatedDateTime: new Date().toISOString(),
        UpdatedDateTime: new Date().toISOString(),
      });
    },
    deleteItem(index) {
      this.Comments.splice(index, 1);
    },
    async update() {
      var payload = { Items: this.Comments };
      var res = await this.$client.post("/AboutUs/UpdateComments", payload);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.getData();
      }
    },
  },
};
</script>

<style lang="less">
#fixed-fields {
  .content-wrapper {
    margin-top: 20px;
  }

  .card-container {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  .card-edit-section {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .rate-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      .rate-label {
        font-weight: 500;
        color: #606266;
      }
    }
  }

  .part {
    .header {
      margin-bottom: 10px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .mid-text {
        font-weight: 500;
        font-size: 16px;
        color: #44566c;
      }

      .delete-icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff4d4f;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s;

        i {
          color: white;
          font-size: 16px;
        }

        &:hover {
          transform: scale(1.05);
          background: #ff7875;
        }
      }
    }

    .images-container {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    }

    .image-wrapper {
      .image-title {
        font-weight: 500;
        font-size: 14px;
        color: #44566c;
        margin-bottom: 8px;
      }
    }

    .image {
      cursor: pointer;
      width: 135px;
      height: 240px;
      background-position: center;
      background-size: cover !important;
      background: #44566c;
      opacity: 0.6;
      border-radius: 10px;
      box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      &.upload-button {
        opacity: 0.6;
        div,
        i {
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          align-self: center;
        }
      }

      &.image-preview {
        opacity: 1;
        .image-overlay {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          display: none;
        }

        &:hover .image-overlay {
          display: block;
          background: rgba(0, 0, 0, 0.15);
          border-top-right-radius: 10px;
        }

        .remove-icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ff4d4f;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1);
            color: #ff7875;
          }
        }
      }
    }
  }
}
</style>

<script>
// core version + navigation, pagination, autoplay modules:
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
// import styles bundle
import "swiper/swiper-bundle.min.css";

export default {
  props: ["data"],
  data() {
    return {
      activeIndex: 0,
      converstationStatus: false,
    };
  },
  methods: {
    goProfile() {
      this.$router.push({ name: "Profilim" });
    },
    async getAppointmentList() {
      await this.$store.dispatch("getListUserAppointments", {
        PageSize: 100,
        SearchTerm: null,
        Status: null,
        Page: 1,
      });
    },
    async startConversation() {
      console.log("startConversation");
      await this.getAppointmentList();
      console.log(this.appointmentList);
      this.$nextTick(async () => {
        var appointment = await this.appointmentList.Appointments.filter((item) => (item.Status == 1 || item.Status == 2) && item.DoctorUser != null)[0];
        if (appointment) {
          this.$router.push("/kullanici/gorusme-odasi/" + appointment.ID);
        } else {
          this.$router.push({ path: "/kullanici/gorusme-baslat" });
        }
      });
    },
  },
  computed: {
    appointmentList() {
      return this.$store.getters.getAppointments;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    const SECOND = 1000; // milliseconds

    // init Swiper:
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination, Autoplay],

      loop: true,
      autoplay: {
        delay: 3 * SECOND,
        disableOnInteraction: false,
      },
      speed: 2 * SECOND,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
      },

      // Events
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },
};
</script>

<template>
  <!-- Slider main container -->
  <div class="home-slider-container">
    <div class="error-bar" v-if="!user?.IsMernisVerified">
      <i class="menu-icon-bell"></i>
      <div class="error-bar-text">Doktorunuzla görüşmek için lütfen Sağlık Bakanlığı'nın istediği bilgileri doldurun.</div>
      <div class="error-bar-button" @click="goProfile">Bilgileri Gir</div>
    </div>
    <div ref="swiper" class="swiper home-slider">
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide home-slide" :style="{ backgroundImage: `url(${slide.ImageUrl})` }" v-for="slide in data" :key="slide.ID">
          <div class="home-slide-title">{{ slide.Title }}</div>
          <div class="home-slide-content">{{ slide.Description }}</div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <el-button class="start-conversation conv-btn" :disabled="!user?.IsMernisVerified" @click="startConversation" size="medium">
      <div class="icon"></div>
      <div>{{ $localization("web-clientlayout-start-meet") }}</div>
    </el-button>
  </div>
</template>

<style lang="less">
.home-slider-container {
  position: relative;
  max-width: 100%;
  .conv-btn {
    position: absolute;
    bottom: -20px;
    border: 0 !important;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    span {
      display: flex !important;
      & > div {
        display: flex !important;
      }
    }
  }
}

.home-slider {
  max-width: 100%;
  aspect-ratio: 16/5;

  @media (max-width: 768px) {
    aspect-ratio: 16/9;
  }
}

.home-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .home-slide-title {
    font-size: 24px;
    font-weight: bold;
  }

  .home-slide-content {
    font-size: 16px;
    max-width: 70%;
    text-align: center;
  }
}

.home-slider {
  border-radius: 10px;
  background: url("/img/section-bg.jpg");

  .swiper-pagination {
    right: 10px;
    left: unset;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .swiper-pagination-bullet {
      margin: 0 !important;
      height: 12px;
      width: 12px;
      background: gray;
    }

    .swiper-pagination-bullet-active {
      background: white !important;
    }
  }
}
</style>

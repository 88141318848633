<script>
import Slider from "@/components/EntegrationAndDocuments/Slider.vue";

export default {
  name: "EntegrationAndDocuments",
  components: { Slider },
  methods: {}
};
</script>

<template>
  <div class="entegration-and-documents">
    <div class="entegration-and-documents-title">Entegrasyon ve Belgeler</div>
    <slider></slider>
  </div>
</template>

<style scoped lang="less">
.entegration-and-documents {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .entegration-and-documents-title {
    font-weight: bold;
    font-size: 20px;
    color: #121826;
  }
}
</style>
import Vue from "vue";
import axios from "axios";
import router from "../router/index";
// import store from "../store/index"
const client = axios.create();

let token = localStorage.getItem("token");

client.defaults.baseURL = process.env.VUE_APP_APIURL; //canlı-test
//client.defaults.baseURL = "http://127.0.0.1:25007"; //canlı-test
if (token) {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      router.push("/giris");
    }
    return error;
  }
);

client.interceptors.request.use(function (config) {
  // Do something before request is sent
  var lang = localStorage.getItem("language");
  if(lang != null && lang != undefined && lang.length > 0) {
    config.headers.common["language"] = lang;
  }
  return config;
});

Vue.prototype.$client = client;

export { client };

<template>
  <el-dialog id="additionalUpdate" :visible.sync="show" :width="screenWidth > 991 ? '50%' : '90%'" :before-close="handleClose" custom-class="container" :show-close="true">
    <div class="container">
      <div class="title2">{{ $localization("web-please-add-all-informations", "Lütfen gerekli tüm bilgileri giriniz.") }}</div>

      <div class="input-container">
        <div>{{ $localization("web-client-form-name") }}</div>
        <el-input :class="{ 'input-error': !userForm.FirstName }" v-model="userForm.FirstName" :disabled="user.IsMernisVerified"></el-input>
        <span class="error">{{ $errorMessage("FirstName", ValidationErrors) }}</span>
      </div>

      <div class="input-container">
        <div>{{ $localization("web-client-form-surname") }}</div>
        <el-input :class="{ 'input-error': !userForm.LastName }" v-model="userForm.LastName" :disabled="user.IsMernisVerified"></el-input>
        <span class="error">{{ $errorMessage("LastName", ValidationErrors) }}</span>
      </div>

      <div class="input-container">
        <div>{{ $localization("web-register-nationality") }}</div>
        <el-select
          v-if="countryList.length > 0"
          :class="{ 'input-error': !userForm.CitizenshipId }"
          v-model="userForm.CitizenshipId"
          style="width: 100%"
          class="nationality"
          :placeholder="$localization('web-register-nationality')"
          filterable
          clearable
          @change="
            userForm.City.ID = null;
            userForm.District.ID = null;
          "
          :disabled="user.IsMernisVerified">
          <el-option v-for="item in countryList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
        </el-select>
        <span class="error">{{ $errorMessage("CitizenshipId", ValidationErrors) }}</span>
      </div>

      <template v-if="countryList.find((x) => x.ID == userForm.CitizenshipId) && countryList.find((x) => x.ID == userForm.CitizenshipId).Code != 'TR'">
        <div class="input-container">
          <div>{{ $localization("web-client-form-passport-number") }}</div>
          <el-input :class="{ 'input-error': !userForm.PassportNumber }" maxlength="30" :placeholder="$localization('web-register-passport-number')" class="tc" v-model="userForm.PassportNumber"></el-input>
          <span class="error">{{ $errorMessage("PassportNumber", ValidationErrors) }}</span>
        </div>
      </template>
      <template v-else>
        <div class="input-container">
          <div>{{ $localization("web-client-form-identity-number") }}</div>
          <el-input :class="{ 'input-error': !userForm.IdentityNumber }" maxlength="11" v-mask="mask.Identity" :placeholder="$localization('web-register-indentity-number')" class="tc" v-model="userForm.IdentityNumber" :disabled="user.IsMernisVerified"></el-input>
          <span class="error">{{ $errorMessage("IdentityNumber", ValidationErrors) }}</span>
        </div>
      </template>

      <div v-if="countryList.find((x) => x.ID == userForm.CitizenshipId) && countryList.find((x) => x.ID == userForm.CitizenshipId).Code == 'TR'">
        <el-row :gutter="8">
          <el-col :span="12">
            <div class="input-container">
              <div>{{ $localization("web-register-city") }}</div>
              <el-select
                :class="{ 'input-error': !userForm.City.ID }"
                v-model="userForm.City.ID"
                style="width: 100%"
                class="tc"
                :placeholder="$localization('web-register-city')"
                filterable
                clearable
                @change="
                  userForm.District.ID = null;
                  getDistrictList(userForm.City.ID);
                ">
                <el-option v-for="item in cityList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
              </el-select>
              <span class="error">{{ $errorMessage("CityId", ValidationErrors) }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="input-container">
              <div>{{ $localization("web-register-district") }}</div>
              <el-select :class="{ 'input-error': !userForm.District.ID }" v-model="userForm.District.ID" style="width: 100%" class="tc" :placeholder="$localization('web-register-district')" filterable clearable :disabled="userForm.City.ID == null || userForm.City.ID == ''">
                <el-option v-for="item in districtList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
              </el-select>
              <span class="error">{{ $errorMessage("DistrictId", ValidationErrors) }}</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="input-container">
        <div class="text">{{ $localization("web-client-form-birthdate") }}</div>
        <el-date-picker style="width: 100%" :class="{ 'input-error': !userForm.BirthDate }" v-model="userForm.BirthDate" :disabled="user.IsMernisVerified" format="dd.MM.yyyy" type="date" :placeholder="$localization('web-client-form-please-select')" default-value="1980-01-01" prefix-icon="none"> </el-date-picker>
        <span class="error">{{ $errorMessage("Birthdate", ValidationErrors) }}</span>
      </div>

      <div class="input-container">
        <div>{{ $localization("web-client-form-gender") }}</div>
        <el-select :class="{ 'input-error': !userForm.Gender }" v-model="userForm.Gender" :placeholder="$localization('web-client-form-please-select')" v-if="genderEnums != null">
          <el-option v-for="(item, index) in genderEnums.Enums" :key="index" :label="item.DisplayName" :value="item.Key"> </el-option>
        </el-select>
        <span class="error">{{ $errorMessage("Gender", ValidationErrors) }}</span>
      </div>

      <el-button class="save-button" @click="updateProfileSettings()" :loading="loading" :disabled="loading"><i class="icon-refresh" v-if="!loading"></i>{{ $localization("web-client-form-save") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as ActionNames from "@/store/action-names";
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    //!!! FC 29/05/24++ , true ise (defsult) giriş yapan kullanıcı mernis doğlulaması,
    // false ise puserform kullanucısının admin tarafından yapılan dorğrulaması
    islogeduser: {
      type: Boolean,
      required: false,
      default: true,
    },

    puserForm: {
      type: Object,
      required: false,
      default: null, //JSON.parse(JSON.stringify(this.$store.getters.getUser)),
    },
    /*
    setup(puserForm) {
      this.userForm = puserForm;
      console.log("props.userForm.ID = " + puserForm.ID);
    },
    */
  },
  beforeMount() {
    if (this.userForm.City == null) {
      this.userForm.City = { ID: null };
    }
    if (this.userForm.District == null) {
      this.userForm.District = { ID: null };
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    if (this.userForm.City.ID != null) {
      this.getDistrictList(this.userForm.City.ID);
    }
  },
  computed: {
    user() {
      //!!! FC 29/05/24++ Eger login olan kullanıcı kontrol edilmiyorsa
      if (!this.islogeduser) {
        return this.userForm;
      } else {
        return this.$store.getters.getUser;
      }
    },
    countryList() {
      return this.$store.getters.getCountryList;
    },
    cityList() {
      return this.$store.getters.getCityList;
    },
    districtList() {
      return this.$store.getters.getDistrictList;
    },
    genderEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Gender");
    },
  },
  data() {
    return {
      screenWidth: 0,
      //show: true,
      userForm: JSON.parse(JSON.stringify(this.$store.getters.getUser)),
      ValidationErrors: [],
      mask: {
        Identity: "###########",
      },
      loading: false,
    };
  },
  methods: {
    handleClose() {
      this.$emit("update:show", false);
      //this.show = false;
    },
    async getDistrictList(id) {
      await this.$store.dispatch(ActionNames.GET_DISTRICT_LIST, id);
    },
    async updateProfileSettings() {
      this.loading = true;
      this.ValidationErrors = [];
      //!!! FC 31/05/24++ Tarihi duzenliyoruz cunku saate gore gun atlatıyor
      var tmpdate = this.userForm.BirthDate;
      if (this.user.IsMernisVerified == null || this.user.IsMernisVerified == false) {
        if (tmpdate != null) {
          tmpdate = this.$moment(tmpdate).utc(true); // FC Saati koruyarak utc ye ceviriyoruz (3 saat geri almıyor)
        }
      }

      var updateObj = {
        PhoneNumber: this.user.PhoneNumber,
        Email: this.user.Email,
        Address: this.user.Address,
        Latitude: this.user.Latitude,
        Longitude: this.user.Longitude,
        Weight: this.user.Weight,
        Height: this.user.Height,
        FirstName: this.userForm.FirstName,
        LastName: this.userForm.LastName,
        CitizenshipId: this.userForm.CitizenshipId,
        IdentityNumber: this.userForm.IdentityNumber,
        PassportNumber: this.userForm.PassportNumber,
        CityId: this.userForm.City.ID,
        DistrictId: this.userForm.District.ID,
        BirthDate: tmpdate,
        Gender: this.userForm.Gender,
        //!!! FC 31/05/24++
        isloggeduser: this.islogeduser,
        UserId: this.user.ID,
      };
      var res = await this.$client.post("/Account/UpdateUserPersonalInfo", updateObj);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        //!!! FC 29/05/24++ Eğer login olan kullanıcı  ise storu update ediyoruz
        if (this.islogeduser) {
          await this.$store.dispatch("getUser");
        }
        this.handleClose();
      }
      this.loading = false;
    },
  },
  //!!! FC 29/05/24++ Disaridan user paslanmıs ıse update ediyoruz (Adminden mernis kontrolunde)
  watch: {
    puserForm: {
      deep: true,
      handler() {
        if (this.puserForm != null) {
          this.userForm = this.puserForm;
          if (this.userForm.City == null) {
            this.userForm.City = { ID: null };
          }
          if (this.userForm.District == null) {
            this.userForm.District = { ID: null };
          }
        }
      },
    },
  },
};
</script>

<style lang="less">
@c-blue-1: #234a98;

.input-error {
  .el-input__inner {
    border: 1px solid #ff0000 !important;
  }
}

.container {
  .el-icon-close:hover {
    color: @c-blue-1 !important;
  }

  display: flex;
  flex-direction: column;
  //padding-left: 20px;
  //padding-right: 20px;

  @media screen and (max-width: 670px) {
    padding-left: 0;
    padding-right: 0;
  }

  .title2 {
    font-weight: 700;
    font-size: 20px;
    color: #44566c;
    margin-bottom: 20px;
    text-align: center;
  }

  .input-container {
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin-bottom: 15px;

    color: #8697a8;
    font-weight: 400;
    font-size: 15px;

    .el-date-editor {
      .el-input__inner {
        padding-left: 15px;
      }
    }
  }

  .save-button {
    &:hover {
      color: white;
      background: #234a98;
    }

    color: white;
    background: #234a98;

    box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
    background-color: #234a98;
    color: #fff;
    width: 116px;
    height: 40px;
    font-weight: 500;
    font-size: 15px;
    align-self: flex-end;
    margin-top: 10px;
    border-radius: 5px;

    i {
      float: left;
    }
  }
}
</style>

<template>
  <div class="blog-home-container">
    <div class="blog-header">
      <div class="blog-title">Bloglar</div>
      <div class="blog-more" @click="goBlogs">Tüm yazılar</div>
    </div>
    <div class="blog-slider">
      <blog-slider :blogs="featuredBlogs.Items"></blog-slider>
    </div>
  </div>
</template>

<script>
import BlogSlider from "./BlogSlider.vue";
export default {
  name: "Blogs",
  components: { BlogSlider },
  data() {
    return {
      blogs: [],
    };
  },
  mounted() {
    this.$store.dispatch("getFeaturedBlogs");
  },
  computed: {
    featuredBlogs() {
      return this.$store.getters["getFeaturedBlogs"];
    },
  },
  methods: {
    goBlogs() {
      this.$router.push("/kullanici/blog-yazilarimiz");
    },
  },
};
</script>

<style lang="less" scoped>
.blog-home-container {
  display: flex;
  gap: 20px;
  flex-direction: column;

  .blog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .blog-title {
      font-weight: bold;
      font-size: 20px;
      color: #121826;
    }
    .blog-more {
      color: #121826;
      font-size: 16px;
      font-weight: 500;
      user-select: none;
      cursor: pointer;
      &:hover {
        color: #121826;
        text-decoration: underline;
      }
    }
  }
}
</style>

import { client } from "@/helpers/axiosHelpers";
import { Message } from "element-ui";
export default {
  async getBlogs(context, payload) {
    var res = await client.post("/Blog/ListBlogPosts", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setBlogs", res.data.Data);
      return res.data.Data;
    }
  },
  async getFeaturedBlogs(context, payload) {
    var res = await client.post("/Blog/ListFeaturedBlogPosts", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setFeaturedBlogs", res.data.Data);
      return res.data.Data;
    }
  },
};

<template>
  <el-container class="layout-container">
    <el-aside>
      <div class="logo">
        <img src="../assets/img/logo.svg" class="logo" v-if="isCollapse == false" />
        <img src="../assets/img/Compact.png" class="mobile" v-else />
      </div>
      <el-row class="tac">
        <el-col :span="24">
          <el-menu :default-active="activeMenu" class="el-menu-vertical-demo side-menu" :collapse="isCollapse" :router="true">
            <el-menu-item index="/admin">
              <i class="menu-icon-dashboard"></i>
              <span slot="title">Dashboard</span>
            </el-menu-item>
            <el-menu-item index="/admin/adminler" v-if="user.Role == 12">
              <i class="icon-user-crown"></i>
              <span slot="title">Adminler</span>
            </el-menu-item>
            <el-menu-item index="/admin/kullanicilar">
              <i class="menu-icon-user"></i>
              <span slot="title">Kullanıcılar</span>
            </el-menu-item>
            <el-menu-item index="/admin/mesajlar">
              <i class="menu-icon-messages"></i>
              <span slot="title">Mesajlar</span>
            </el-menu-item>
            <el-menu-item index="/admin/dosyalar">
              <i class="menu-icon-folder"></i>
              <span slot="title">Dosyalar</span>
            </el-menu-item>
            <el-menu-item index="/admin/segmentasyon">
              <i class="icon-user-crown"></i>
              <span slot="title">Segmentasyon</span>
            </el-menu-item>

            <el-menu-item index="/admin/doktorlar">
              <i class="menu-icon-contacts"></i>
              <span slot="title">Doktorlar</span>
            </el-menu-item>
            <el-menu-item index="/admin/hemsireler">
              <i class="menu-icon-nurse"></i>
              <span slot="title">Hemşireler</span>
            </el-menu-item>
            <el-menu-item index="/admin/branslar">
              <i class="menu-icon-contacts"></i>
              <span slot="title">Branşlar</span>
            </el-menu-item>
            <el-menu-item index="/admin/gecmis-gorusmeler">
              <i class="menu-icon-details"></i>
              <span slot="title">Tüm Görüşmeler</span>
            </el-menu-item>
            <el-menu-item index="/admin/odeme-gecmisi">
              <i class="menu-icon-credit-card"></i>
              <span slot="title">Ödeme Geçmişi</span>
            </el-menu-item>
            <el-menu-item index="/admin/islem-gecmisi">
              <i class="menu-icon-clock-rotate"></i>
              <span slot="title">İşlem Geçmişi</span>
            </el-menu-item>
            <!-- <el-menu-item index="/admin/kayit-indirme-gecmisi">
              <i class="menu-icon-record-history"></i>
              <span slot="title">Kayıt İndirme Geçmişi</span>
            </el-menu-item> -->
            <el-menu-item index="/admin/doktor-not-gecmisi">
              <i class="menu-icon-sticky"></i>
              <span slot="title" style="display: flex; gap: 10px; justify-content: flex-end"
                >Doktor Not Geçmişi <span style="color: red" v-if="user.UnreadNoteRequestCount > 0">({{ user.UnreadNoteRequestCount }}) </span>
              </span>
            </el-menu-item>
            <!-- <el-menu-item index="/admin/gorusme-kayit-onay">
              <i class="menu-icon-video-solid"></i>
              <span slot="title">Görüşme Kayıt Onay</span>
            </el-menu-item> -->
            <el-menu-item index="/admin/faq">
              <i class="menu-icon-help"></i>
              <span slot="title">Sıkça Sorulan Sorular</span>
            </el-menu-item>
            <el-menu-item index="/admin/destek-talebi">
              <i class="menu-icon-drafts"></i>
              <span slot="title">Destek Talepleri</span>
            </el-menu-item>
            <!-- <el-menu-item index="/admin/ayarlar">
              <i class="menu-icon-settings"></i>
              <span>Ayarlar</span>
            </el-menu-item> -->
            <el-submenu index="1">
              <template slot="title">
                <i class="menu-icon-forms"></i>
                <span slot="title" style="font-weight: 500; font-size: 15px; line-height: 18px; color: #44566c; padding-left: 10px">Blog</span>
              </template>
              <el-menu-item index="/admin/kategoriler">Kategoriler</el-menu-item>
              <el-menu-item index="/admin/makaleler">Makaleler</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <i class="icon-home"></i>
                <span slot="title" style="font-weight: 500; font-size: 15px; line-height: 18px; color: #44566c; padding-left: 10px">Web Sitesi</span>
              </template>
              <!--    <el-menu-item index="/admin/slider">Slider</el-menu-item>
              <el-menu-item index="/admin/dilsecenekleri">Dil Seçenekleri</el-menu-item> -->
              <el-menu-item index="/admin/mobilanasayfakartlar">Mobil Uygulama Kartlar</el-menu-item>
              <el-menu-item index="/admin/onboardings">Onboarding</el-menu-item>
              <el-menu-item index="/admin/home-comments">Yorumlar</el-menu-item>
              <el-menu-item index="/admin/sabitalanlar">Sabit Alanlar</el-menu-item>
              <el-menu-item index="/admin/web-dosyalari">Dosyalar</el-menu-item>
            </el-submenu>

            <el-submenu index="3">
              <template slot="title">
                <i class="menu-icon-language"></i>
                <span slot="title" style="font-weight: 500; font-size: 15px; line-height: 18px; color: #44566c; padding-left: 10px">Yerelleştirme</span>
              </template>
              <el-menu-item index="/admin/sistem-dilleri">Sistem Dilleri</el-menu-item>
              <el-menu-item index="/admin/yerellestirme">Yerelleştirme</el-menu-item>
            </el-submenu>

            <el-menu-item index="/admin/sozlesmeler">
              <i class="icon-pages"></i>
              <span slot="title">Sözleşmeler</span>
            </el-menu-item>

            <el-menu-item index="/admin/skrskaydi">
              <i class="menu-icon-credit-card"></i>
              <span slot="title">SKRS İşlemleri</span>
            </el-menu-item>
            <el-menu-item index="/admin/kurumlar">
              <i class="menu-icon-credit-card"></i>
              <span slot="title">Kurumlar</span>
            </el-menu-item>
            <el-menu-item index="/admin/hizmetler">
              <i class="menu-icon-credit-card"></i>
              <span slot="title">Hizmetler</span>
            </el-menu-item>

            <!-- alt menulu
            <el-submenu index="4">
              <template slot="title">
                <i class="menu-icon-forms"></i>
                <span slot="title"
                  style="font-weight: 500; font-size: 15px; line-height: 18px; color: #44566c; padding-left: 10px">SKRS
                  İşlemleri</span>
              </template>
              <el-menu-item index="/admin/skrskaydi">Kayıt/Güncelleme/Silme</el-menu-item>
              <el-menu-item index="/admin/skrsgoruntuleme">Görüntüleme</el-menu-item>
            </el-submenu>
              -->
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-container>
      <el-header>
        <div class="left">
          <img src="../assets/img/Compact.png" class="mobile" />
          <div @click="isCollapse = !isCollapse" class="menu-collapse"></div>
          <div @click="drawerMenu = !drawerMenu" class="menu-drawer"></div>
        </div>

        <div class="right">
          <div class="buttons-group">
            <div class="user-info">
              <div class="user-avatar">
                <div class="short">{{ user.FirstName[0] }}{{ user.LastName[0] }}</div>
              </div>
              <div class="user-name">
                <span>{{ user.FirstName }} {{ user.LastName }}</span>
              </div>
            </div>
          </div>
          <div class="menu-icon-logout" @click="logout()"></div>
        </div>
      </el-header>
      <portal-target name="new-header"> </portal-target>
      <el-main class="admin-main-container">
        <vue-page-transition name="fade-in-up">
          <router-view />
        </vue-page-transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: this.$route.path,
      isCollapse: false,
    };
  },
  name: "ClientLayout",
  mounted() {
    if (window.innerWidth < 768) {
      this.isCollapse = true;
    }
    this.$socket = this.$io(process.env.VUE_APP_SOCKETURL, {
      transports: ["websocket"],
      upgrade: false,
      query: {
        token: localStorage.getItem("token"),
      },
    });
    this.$socket.off("reconnect_attempt");
    this.$socket.on("reconnect_attempt", () => {
      console.log("reconnect_attempt");
    });
    this.$socket.off("disconnect");
    this.$socket.on("disconnect", () => {
      console.log("disconnect");
    });
    this.$socket.off("connect");
    this.$socket.on("connect", () => {
      this.$socket.emit("subscribe", {
        channel: "presence-user-" + this.user.ID,
        token: "",
      });
    });
    this.$socket.off("subscribe_success");
    this.$socket.on("subscribe_success", (room) => {
      console.log(room);
      this.$socket.off("presence-user-" + this.user.ID + "-waitingApprovalCount");
      this.$socket.on("presence-user-" + this.user.ID + "-waitingApprovalCount", (message) => {
        console.log(message, "mesaj");

        this.user.UnreadNoteRequestCount += 1;
      });
    });
    this.$socket.emit("subscribe", {
      channel: "presence-user-" + this.user.ID,
      token: "",
    });
  },
  methods: {
    async logout() {
      await this.$client.post("/Account/SignOut");
      localStorage.removeItem("token");
      this.$store.state.auth.user = null;
      this.$router.push({ name: "Login" });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    "$route.params": function () {
      this.activeMenu = this.$route.path;
    },
  },
};
</script>
<style lang="less">
.el-menu-collapse {
  width: unset;
}
</style>

<template>
  <!-- Slider main container -->
  <div ref="swiper" class="swiper blog-slider">
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide blog-slider-slide" v-for="(blog, index) in blogs" :key="index">
        <blog-card :blog="blog"></blog-card>
      </div>
    </div>
  </div>
</template>

<script>
// core version + navigation, pagination, autoplay modules:
import Swiper, { Autoplay } from "swiper";
// import styles bundle
import "swiper/swiper-bundle.min.css";
import BlogCard from "./BlogCard.vue";
export default {
  components: { BlogCard },
  props: {
    blogs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  mounted() {
    const SECOND = 2000; // milliseconds

    // init Swiper:
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Autoplay],
      loop: true,
      autoplay: {
        delay: 3 * SECOND,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
      speed: 2 * SECOND,
      slidesPerView: 1,
      centeredSlides: false,
      spaceBetween: 10,
      // Events
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },
};
</script>

<style lang="less">
.blog-slider {
  max-width: 100%;
  width: auto;
  user-select: none;
}

.blog-slider-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: white;
}

.blog-slider-slider {
  border-radius: 10px;
  background-color: blue;

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .swiper-pagination-bullet {
      margin: 0 !important;
      height: 12px;
      width: 12px;
    }

    .swiper-pagination-bullet-active {
      background: white !important;
    }
  }
}
</style>

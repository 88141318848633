<template>
  <div id="waiting-room">
    <el-alert title="Uyarı" type="warning" show-icon> {{ $localization("web-waitingroom-cam-mic-permission") }}</el-alert>

    <div class="top">
      <div class="title">{{ $localization("web-waitingroom-meet-will-be-begin") }}</div>
      <p>
        {{ $localization("web-waitingroom-time-to-left-begin-meet") }} <span>{{ $localization("web-waitingroom-5-minutes") }}</span>
      </p>
      <img src="../../assets/img/waiting-pose.jpg" alt="" style="width: 100%" />
      <el-button class="primary-btn" @click="openDialog()">
        <i class="icon-task"></i>
        {{ $localization("web-waitingroom-write-your-complaint") }}
      </el-button>
    </div>
    <!--
    <div class="did-you-know-these">
      <div class="slider owl-theme owl-carousel">
        <div class="item" v-for="(item, index) in items" :key="index">
          <div class="item-inner">
            <h2>{{ $localization("web-waitingroom-are-you-know") }}</h2>
            <div>
              <p v-html="item">{{ item }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  --></div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      //42
      //   items: [
      //   "Çocuk sahibi olamıyor musunuz? Sorun belki de kromozomlarınızdadır. Bilgi almak için <a href='https://elra.health/blog/infertilite' target='_blank'>tıklayın</a>.",
      //   "Anne sütü ile beslenmiş bir bebek güçlü, mutlu ve sağlıklı büyür.",
      //   "Ten teması, bebeğinizle aranızdaki bağı güçlendirir.",
      //   "Dünya Sağlık Örgütü’ne göre 5 yaşından küçük her çocuğun gelişiminin izlenmesi gerekmektedir.",
      //   "Bebek ve çocuk izlemlerinde amaç, hastalığa zemin hazırlayan koşulların önceden saptanarak önlenmesidir.",
      //   "Çocuk sağlığı izleminin amacı çocuğun her açıdan sağlıklı büyümesinin sağlanmasıdır.",
      //   "Çocuğun gelişiminin takip edilmesi ve hastalıkların erken tanısı için belirli aralıklarla doktor kontrolü çok önemlidir.",
      //   "Çocuğun büyümesinin takibi için ağırlık, boy ve baş çevresi ölçümü yapılmalı ve persentil eğrilerine göre değerlendirilmelidir.",
      //   "Çocuğunuzun Sağlık Bakanlığı tarafından belirlenen zamanlardaki aşılarını aksatmayın.",
      //   "Çocuğunuzun gelişimi için 0-12 ay arasında D vitamini desteği kullanmayı unutmayın.",
      //   "Çocuğunuza 4.aydan itibaren demir takviyesi kullanma konusunda doktorunuza danışınız.",
      //   "Otizm Spektrum Bozukluğu; belirtileri yaşamın ilk üç yılı içinde ortaya çıkan nörogelişimsel bir bozukluktur.",
      //   "Tüm çocuklara 18 ve 24. aylarda otizm spektrum bozukluğu için değerlendirme yapılmalıdır.",
      //   "Otizm, ne kadar erken yaşta tanınır ve uygun bir şekilde yönlendirilirse, tedavisinde o kadar olumlu sonuçlar alınır. ",
      //   "0-3 ay arası bebekler anne sesini tanır ve sakinleşir.",
      //   "18-24 ay arası çocuklar 20’ye yakın kelimeyi söyleyebilir, 2 kelimelik cümleler kurabilir ve basit komutları yerine getirebilir.",
      //   "Her yenidoğan bebeğe mutlaka işitme taraması yapılmalıdır.",
      //   "Yenidoğan tarama programı kapsamında; doğan her bebekten doğumdan 48 saat sonra (beslenmenin ardından) topuk kan örneği alınmalıdır.",
      //   "Müziğin; bebekleri rahatlatan, iş yerinde stresi azaltan, dikkati artırarak depresyonla baş etmeyi destekleyen ve günlük hayatta verimliliği arttıran gücünü biliyor muydunuz? Bugünün müzik önerisi “Adagio in E Major , K.261” “Mozart”",
      //   "Mozart etkisi nedir? Klasik müziğin etkisi. Bu etkiyle beyin uyarılır ve stres azalır. Bu bağlamda , hafıza ve konsantrasyonu artırmasından dolayı daha iyi çalışmanızı sağlar. Kısaca, verimliliği ve üretkenliği arttırır.",
      //   "Çocuk sağlığı izleminin amacı çocuğun her açıdan sağlıklı büyümesinin sağlanmasıdır.",
      //   "Kronik yorgunluk ve unutkanlığın altında vitamin eksikliği olabilir. ",
      //   "Ailesel kanserler kaderiniz olmayabilir. Meme ve over kanserleri başta olmak üzere birçok kanserde, kanserli  hastada  mutasyon belirlendikten sonra, diğer aile üyelerinin bu mutasyonu taşıyıp taşımadığına bakarak risk altındaki kişiler belirlenir.  Erken tanı ve kişiye özel tedavi olanağı sağlanmış olur.",
      //   "40 yaşından sonra her kadın meme kanseri riski açısından 2 yılda bir mamografi çektirmelidir.",
      //   "Kolon kanseri riski açısından 50 yaş üzerindeki tüm bireylere 10 yılda bir koloskopi taraması önerilir.",
      //   "Gizli şeker denilen durum yani tıbbi adıyla prediyabet, kan şekeri seviyesinin normalden yüksek olduğu fakat diyabet hastalığının oluşmadığı durumdur.",
      //   "Her kadına ayda bir kez adet döneminden 5-7 gün sonra kendi kendine meme muayenesi yapması önerilir. Ele gelen kitle, memede asimetri,şişlik gibi durumlarda klinik muayene için doktora başvurmalıdır.",
      //   "HbA1c diyabet hastalığının tanısında ve tedavinin takibinde kullanılan bir testtir. Kandaki son 2 ila 3 aydaki ortalama kan şekerini yansıtır.",
      //   "Çok su içme isteği, çok sık idrara çıkma, çabuk yorulma ve sinirlenme gibi şikayetleriniz varsa diyabet hastalığı açısından kontrol edilmenizde fayda vardır.",
      //   "Türkiye'de birden fazla olan acil çağrı numaraları (155 Polis İmdat, 156 Jandarma İmdat, 158 Sahil Güvenlik, 110 Yangın İhbar, 112 Sıhhi İmdat, 177 Orman Yangın İhbar, 122 AFAD) tek numara “112” altında toplandı.",
      //   "Antibiyotikler, sadece bakteriyel enfeksiyonlara etki eden ilaç grubudur. Gereksiz ve yanlış kullanımı ilaç direncine ve toplum sağlığı açısından riskli bir duruma neden olur.",
      //   "Sağlıklı bir beden gibi sağlıklı bir psikoloji için de aktif bir yaşam önemlidir. Spor yapmak sağlığı birçok noktadan iyileştirir. “Sağlam kafa sağlam vücutta bulunur.Bilgi almak için <a href='https://elra.health/blog/yoganin-sagligimiza-yarari' target='_blank'>tıklayın</a>.",
      //   "Bazen durmak da önemlidir. Kendi içine bakmak veya hayatını gözden geçirmek. Böylesi dönemlerde farkındalığı artırmak için meditasyonu deneyebilirsiniz. <a href='https://elra.health/blog/meditasyon' target='_blank'>tıklayın</a>.",
      //   "Düzenli test oluyor musunuz? Nasıl korunmanız gerektiğini biliyor musunuz? Belki de bu konuları bilmeyerek fazla risk alıyorsunuzdur. Herkes için önemli ve bitmeyen bir salgını öğrenmek için geç kalmadınız. <a href='https://elra.health/blog/hiv-nasil-bulasir' target='_blank'>tıklayın</a>.",
      //   "İçinizdeki ateşi belki de siz tetikliyorsunuz. Yediklerinizin gastrit üzerinde etkili olabileceğini biliyor muydunuz? <a href='https://elra.health/blog/gastrit-ve-belirtileri' target='_blank'>tıklayın</a>.",
      //   "Ateş bacayı sarmasın. İçinizdeki yanmalar ve acı sular birçok şeye de neden olabilir. Reflünün ilaçlar ve yaşam tarzından etkilendiğini biliyor muydunuz? <a href='https://elra.health/blog/reflu' target='_blank'>tıklayın</a>.",
      //   "Akraba evliliği bazı genetik hastalıklar için risk faktörüdür, çocuk sahibi olmadan önce genetik danışma almanız önerilir.",
      //   "Erken başlangıçlı Alzheimer genetik kökenli olabilir, genetik uzmanına danışarak riskinizi öğrenebilirsiniz.",
      //   "Erkek çocuklarında inmemiş testis erken yaşta tedavi ( 1 yaşına kadar ) edilmezse, kısırlığa yol açabilir.",
      //   "Çocuklarda belli aralıklarla gelen karın ağrısı ve ateş FMF (Ailesel Akdeniz Ateşi) hastalığının habercisi olabilir, lütfen çocuk hastalıkları uzmanına başvurunuz. ",
      //   "Tedavi edilmeyen uzun süreli depresyonun Alzheimer Hastalığı riskini artırdığını biliyor muydunuz? ",
      //   "Bağırsaklarınız ne kadar sağlıklıysa o kadar sağlıklı olabilirsiniz. ",
      //  ],
      items: [
        this.$localization("web-waitingroom-info1-text"),
        this.$localization("web-waitingroom-info2-text"),
        this.$localization("web-waitingroom-info3-text"),
        this.$localization("web-waitingroom-info4-text"),
        this.$localization("web-waitingroom-info5-text"),
        this.$localization("web-waitingroom-info6-text"),
        this.$localization("web-waitingroom-info7-text"),
        this.$localization("web-waitingroom-info8-text"),
        this.$localization("web-waitingroom-info9-text"),
        this.$localization("web-waitingroom-info10-text"),
        this.$localization("web-waitingroom-info11-text"),
        this.$localization("web-waitingroom-info12-text"),
        this.$localization("web-waitingroom-info13-text"),
        this.$localization("web-waitingroom-info14-text"),
        this.$localization("web-waitingroom-info15-text"),
        this.$localization("web-waitingroom-info16-text"),
        this.$localization("web-waitingroom-info17-text"),
        this.$localization("web-waitingroom-info18-text"),
        this.$localization("web-waitingroom-info19-text"),
        this.$localization("web-waitingroom-info19-text"),
        this.$localization("web-waitingroom-info20-text"),
        this.$localization("web-waitingroom-info21-text"),
        this.$localization("web-waitingroom-info22-text"),
        this.$localization("web-waitingroom-info23-text"),
        this.$localization("web-waitingroom-info24-text"),
        this.$localization("web-waitingroom-info25-text"),
        this.$localization("web-waitingroom-info26-text"),
        this.$localization("web-waitingroom-info27-text"),
        this.$localization("web-waitingroom-info28-text"),
        this.$localization("web-waitingroom-info29-text"),
        this.$localization("web-waitingroom-info30-text"),
        this.$localization("web-waitingroom-info31-text"),
        this.$localization("web-waitingroom-info32-text"),
        this.$localization("web-waitingroom-info33-text"),
        this.$localization("web-waitingroom-info34-text"),
        this.$localization("web-waitingroom-info35-text"),
        this.$localization("web-waitingroom-info36-text"),
        this.$localization("web-waitingroom-info37-text"),
        this.$localization("web-waitingroom-info38-text"),
        this.$localization("web-waitingroom-info39-text"),
        this.$localization("web-waitingroom-info40-text"),
        this.$localization("web-waitingroom-info41-text"),
        this.$localization("web-waitingroom-info42-text"),
      ],
    };
  },
  methods: {
    openDialog() {
      this.$emit("openDialog");
    },
  },
  beforeMount() {
    window.jQuery(document).ready(function () {
      window.jQuery(".slider").owlCarousel({
        items: 1,
        loop: true,
        margin: 30,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
      });
    });
  },
};
</script>
<style lang="less">
.el-alert--warning.is-light {
  background-color: #eff4f8 !important;
  color: #234a98 !important;
}

.el-alert {
  width: 90% !important;

  @media screen and (min-width: 700px) {
    width: 40% !important;
  }

  .el-alert__content {
    span {
      font-size: 14px;
    }

    p {
      font-size: 13px;
      padding-bottom: 8px;
      color: #234a98 !important;
    }
  }
}

.writeReportClass {
  .write-report {
    h3 {
      color: #44566c;
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      margin: 0;
      margin-bottom: 33px;
    }

    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        color: #8697a8;
        font-size: 15px !important;
        font-weight: 400;
      }

      .text-edit {
        border: 2px solid #234a98 !important;
        border-radius: 11px !important;

        #toolbar {
          border-radius: 11px 11px 0 0;
          background-color: #eff4f8 !important;
        }

        .quill-editor {
          border-radius: 0 0 11px 11px;

          .ql-container {
            border-radius: 0 0 11px 11px;
            background-color: #eff4f8;
          }
        }
      }
    }

    .file-list {
      display: grid;
      gap: 10px;
      grid-template-columns: auto auto;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
        border-radius: 5px;
        padding: 16px;
        width: 100%;
        background-color: #f8fafb;
        min-height: 72px;

        .file {
          width: 31px;
          height: 40px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.did-you-know-these {
  .slider {
    .owl-stage-outer {
      .owl-item {
        .item {
          .item-inner {
            p {
              font-size: 15px !important;
            }
          }
        }
      }
    }

    .owl-dots {
      display: none;
    }
  }
}
</style>

<template>
  <div class="blog-card" @click="goToBlogDetail(blog.ID)">
    <div class="blog-card-left">
      <div class="blog-card-left-title">{{ blog.Title }}</div>
      <div class="blog-card-left-desc">{{ blog.Summary }}</div>
    </div>
    <div class="blog-card-right">
      <img :src="blog.Cover" :alt="blog.Title" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    blog: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToBlogDetail(id) {
      this.$router.push(`/kullanici/blog-detay/${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.blog-card {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  overflow: hidden;
  padding: 10px;
  gap: 10px;
  user-select: none;
  cursor: pointer;

  .blog-card-left {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex-shrink: 0;
    max-width: 60%;
    .blog-card-left-title {
      font-size: 12px;
      font-weight: bold;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .blog-card-left-desc {
      font-size: 12px;
      font-weight: 400;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .blog-card-right {
    max-width: 40%;
    height: 100%;
    aspect-ratio: 16/9;
    img {
      height: 100%;
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
</style>

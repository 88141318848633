import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  filter: {
    PageSize: 10,
    SearchTerm: null,
    Status: null,
    Page: 1,
    IsFeatured: null,
    MinPublishedDate: null,
    IsActive: null,
    CategoryId: null,
    ForApp: null,
    LanguageCode: null,
  },
  blogs: [],
  featuredBlogs: [],
};
export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div id="interview">
    <div class="appointment-details">
      <div class="all-interviews">
        <div class="type" :class="meetingType == 1 ? 'active' : ''" @click="setMeetingType(1)">
          <span> {{ $localization("web-interview-start-appointment-now") }} </span>
        </div>
        <!--
        <div class="type" :class="meetingType == 2 ? 'active' : ''" @click="setMeetingType(2)">
          <span> {{ $localization("web-interview-start-appointment-specialist") }} </span>
        </div>
      -->
        <!-- <div class="type" :class="meetingType == 3 ? 'active' : ''" @click="setMeetingType(3)">Diğer Sağlık Personelleri</div> -->
      </div>
      <div class="interview-information">
        <div class="title-interview">{{ $localization("web-interview-meet-infos") }}</div>

        <div class="form">
          <div class="form-item">
            <div class="title">{{ $localization("web-interview-patient") }}</div>
            <el-select class="primary-input" v-model="form.PatientId" :placeholder="$localization('web-interview-please-select')">
              <el-option v-for="(item, index) in patient" :key="index" :label="item.ParentId != null || item.ParentId != undefined ? item.FirstName + ' ' + item.LastName + ' ' + $localization('web-interview-kid') : item.FirstName + ' ' + item.LastName + ' ' + $localization('web-interview-me')" :value="item.ID"></el-option>
            </el-select>
            <span class="error">{{ $errorMessage("patientId", ValidationErrors) }}</span>
          </div>
          <div class="form-item">
            <div class="date">
              <div class="title">{{ $localization("web-interview-meet-date") }}</div>
              <el-select class="primary-input" :disabled="true" v-model="form.InterviewType" :placeholder="$localization('web-interview-please-select')" v-if="getEnums != null">
                <el-option v-for="item in getEnums.find((x) => x.Title == 'InterviewType').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"></el-option>
              </el-select>
              <span class="error">{{ $errorMessage("InterviewType", ValidationErrors) }}</span>
            </div>
          </div>
          <div v-if="!isFree && this.appointmentDiscountPrice > 0 && this.MobilePaymentOption" class="form-item">
            <label>{{ $localization("web-interview-payment-type") }}</label>
            <el-radio-group v-model="form.PayTrPayment">
              <el-radio :label="false">{{ $localization("web-interview-payment-type-credit-card") }}</el-radio>
              <el-radio :label="true">{{ $localization("web-interview-payment-type-mobile") }} </el-radio>
            </el-radio-group>
            <span class="error">{{ $errorMessage("PaymentType", ValidationErrors) }}</span>
          </div>

          <template v-if="meetingType == 2 || meetingType == 3">
            <div class="form-item">
              <div class="date">
                <div class="title">{{ $localization("web-interview-speciality") }}</div>
                <el-select class="primary-input" v-model="SpecialityId" :placeholder="$localization('web-interview-please-select-speciality')" @change="setDoctors">
                  <el-option v-for="item in allSpecialities" :key="item.ID" :label="item.Title" :value="item.ID"></el-option>
                </el-select>
                <!-- <el-select v-model="SpecialityId" placeholder="Branş / Uzmanlık Seçiniz.." @change="setDoctors" v-if="meetingType == 2">
                  <el-option v-for="item in allSpecialities.filter((x) => x.Type == true)" :key="item.ID" :label="item.Title" :value="item.ID"></el-option>
                </el-select>
                <el-select v-model="SpecialityId" placeholder="Branş / Uzmanlık Seçiniz.." @change="setDoctors" v-if="meetingType == 3">
                  <el-option v-for="item in allSpecialities.filter((x) => x.Type == false)" :key="item.ID" :label="item.Title" :value="item.ID"></el-option>
                </el-select> -->
                <span class="error">{{ $errorMessage("Speciality", ValidationErrors) }}</span>
              </div>
            </div>
            <div class="form-item">
              <div class="date">
                <div class="title">{{ $localization("web-interview-doctor") }}</div>
                <el-select class="primary-input" v-model="form.DoctorId" :placeholder="$localization('web-interview-please-select-doctor')" :disabled="!SpecialityId" @change="resetDateAndHour">
                  <el-option v-for="item in doctorsList" :key="item.ID" :label="item.Title" :value="item.ID"></el-option>
                </el-select>
                <span class="error">{{ $errorMessage("Speciality", ValidationErrors) }}</span>
              </div>
            </div>
            <template v-if="form.InterviewType == 2">
              <div class="form-item">
                <div class="date">
                  <div class="title">{{ $localization("web-interview-appointment-date") }}</div>
                  <el-date-picker v-model="date" type="date" format="dd-MM-yyyy" :picker-options="pickerOptions" :clearable="false" :placeholder="$localization('web-interview-please-select-date')" @change="setEnableDoctorHour" :disabled="!(form.DoctorId && form.DoctorId != '')"> </el-date-picker>
                  <span class="error">{{ $errorMessage("InterviewDate", ValidationErrors) }}</span>
                </div>
              </div>
              <div class="form-item">
                <div class="date">
                  <div class="title">{{ $localization("web-interview-appointment-hour") }}</div>
                  <el-select class="primary-input" v-model="hour" :placeholder="$localization('web-interview-please-select-hour')" :disabled="!(date && date != '')" :no-data-text="$localization('web-interview-no-appointment-hour-found-available')">
                    <el-option v-for="item in enableDoctorHours" :key="item.TimeStr" :label="item.TimeStr" :value="item.TimeStr"></el-option>
                  </el-select>
                  <span class="error">{{ $errorMessage("InterviewHour", ValidationErrors) }}</span>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <div v-if="!isFree && this.appointmentDiscountPrice > 0" class="credit-card-container">
      <div v-if="!form.PayTrPayment" class="credit-card">
        <div class="payment">
          <div class="title-payment">{{ $localization("web-interview-payment") }}</div>
          <p class="text-payment">{{ $localization("web-interview-please-enter-card-info") }}</p>
        </div>
        <el-radio-group v-model="payWithCard" v-if="user.PaymentMethods.length > 0">
          <el-radio-button :label="true"
            >{{ $localization("web-interview-saved-cards") }} <span style="background: unset" v-if="user.PaymentMethods != null && user.PaymentMethods.length > 0">({{ user.PaymentMethods.length }})</span></el-radio-button
          >
          <el-radio-button :label="false">{{ $localization("web-interview-new-card") }}</el-radio-button>
        </el-radio-group>
        <div class="new-card" v-if="!payWithCard">
          <div class="title">{{ $localization("web-interview-card-info") }}</div>

          <div class="form-item">
            <div class="title">{{ $localization("web-interview-cardholder") }}</div>
            <el-input class="primary-input" placeholder="..." v-model="form.CardName"></el-input>
          </div>
          <div class="form-item">
            <div class="title">{{ $localization("web-interview-cardno") }}</div>
            <el-input class="primary-input" v-mask="mask.CardNO" placeholder="**** **** **** ****" v-model="form.CardNO"></el-input>
          </div>

          <div class="form-container">
            <div class="form-item">
              <div class="title">{{ $localization("web-interview-expire-date") }}</div>
              <el-input class="primary-input" type="text" v-mask="mask.ExpireDate" placeholder="** / **" auto-complete="false" v-model="form.ExpireDate"></el-input>
            </div>
            <div class="form-item">
              <div class="title">{{ $localization("web-interview-cvv") }}</div>
              <el-input class="primary-input" type="password" v-mask="mask.CVV" placeholder="***" v-model="form.CVV" autocomplete="off"></el-input>
            </div>
          </div>
          <div class="form-item">
            <el-switch v-model="saveCard" :active-text="$localization('web-interview-save-my-card')" active-color="#234A98" :inactive-text="$localization('web-interview-dont-save-my-card')"> </el-switch>
          </div>
          <div class="policy">
            <el-tooltip class="item" effect="dark" :content="$localization('web-interview-please-click')" placement="top">
              <div class="policy-container">
                <el-checkbox @change="checkboxChanged($event)" v-model="policyControl.policy1" :append-to-body="true"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(8)">{{ $localization("web-interview-i-red-and-aggree-health-policy") }}</span>
                </div>
              </div>
            </el-tooltip>
            <!-- <el-tooltip class="item" effect="dark" content="Tıklayın" placement="top">
              <div class="policy-container">
                <el-checkbox v-model="policyControl.policy2" :append-to-body="true"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(2)">Online görüşme açık rıza metni okudum onaylıyorum *</span>
                </div>
              </div>
            </el-tooltip> -->
            <!-- <div class="policy-container no-required">
              <el-checkbox v-model="form.Permission"></el-checkbox>
              <div class="text">
                <span @click="form.Permission = !form.Permission">Görüşme kaydı açık rıza onayı</span>
              </div>
            </div> -->
            <img src="/img/iyzico-banner.svg" alt="Iyzico Banner" height="24" style="margin-top: 10px" />
          </div>
          <el-dialog :visible.sync="policyDialog" width="40%" custom-class="policy-dialog">
            <PolicyDialog :data="policy" @checked="checked" />
          </el-dialog>
          <el-button v-if="form.InterviewType == 2" class="start-pay primary-btn" @click="payment()" :disabled="onPayment" :loading="onPayment">
            <div class="icon" v-if="!onPayment"></div>
            <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
          </el-button>
          <el-button v-else class="start-pay primary-btn" @click="beforePayment()" :disabled="onPayment" :loading="onPayment">
            <div class="icon" v-if="!onPayment"></div>
            <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
          </el-button>
          <div id="hidden" style="display: none"></div>
        </div>
        <div class="card-list" v-if="payWithCard">
          <div class="title">{{ $localization("web-interview-my-saved-cards") }}</div>
          <ul class="credit-card-list">
            <li class="item" v-for="(card, index) in user.PaymentMethods" :key="index" :class="{ active: activeCard == card.Id ? true : false }" @click="activeCard = card.Id">
              <div class="icon">
                <i class="el-icon-circle-check"></i>
              </div>
              <div class="card-info">
                <div class="title">
                  {{ card.Title }}
                </div>
                <div class="card-bin">
                  <div class="period-container">
                    <div class="period">
                      {{ card.Bin.slice(0, 4) }}
                    </div>
                    <div class="period">
                      {{ card.Bin.slice(4, 6) }}
                      <div class="icon-period" v-for="i in 2" :key="i"></div>
                    </div>
                    <div class="period">
                      <div class="icon-period" v-for="i in 4" :key="i"></div>
                    </div>
                    <div class="period">
                      <div class="icon-period" v-for="i in 4" :key="i"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-img">
                <img src="/img/cardassocation/mastercard.svg" v-if="card.CardAssociation == 'MASTER_CARD'" alt="" />
                <img src="/img/cardassocation/visa.svg" v-if="card.CardAssociation == 'VISA'" alt="" />
              </div>
            </li>
          </ul>
          <div class="policy">
            <el-tooltip class="item" effect="dark" :content="$localization('web-interview-please-click')" placement="top">
              <div class="policy-container">
                <el-checkbox @change="checkboxChanged($event)" v-model="policyControl.policy1" :append-to-body="true"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(8)">{{ $localization("web-interview-i-red-and-aggree-health-policy") }}</span>
                </div>
              </div>
            </el-tooltip>
            <!-- <el-tooltip class="item" effect="dark" content="Tıklayın" placement="top">
              <div class="policy-container">
                <el-checkbox v-model="policyControl.policy2" :append-to-body="true"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(2)">Online görüşme açık rıza metni okudum onaylıyorum *</span>
                </div>
              </div>
            </el-tooltip> -->

            <!-- <div class="policy-container no-required">
              <el-checkbox v-model="form.Permission"></el-checkbox>
              <div class="text">
                <span @click="form.Permission = !form.Permission">Görüşme kaydı açık rıza onayı</span>
              </div>
            </div> -->
            <img src="/img/iyzico-banner.svg" alt="Iyzico Banner" height="24" style="margin-top: 10px" />
          </div>
          <el-dialog :visible.sync="policyDialog" width="40%" custom-class="policy-dialog">
            <PolicyDialog :data="policy" @checked="checked" />
          </el-dialog>
          <el-button v-if="form.InterviewType == 2" class="start-pay primary-btn" @click="payment()" :disabled="onPayment" :loading="onPayment">
            <div class="icon" v-if="!onPayment"></div>
            <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
          </el-button>
          <el-button v-else class="start-pay primary-btn" @click="beforePayment()" :disabled="onPayment" :loading="onPayment">
            <div class="icon" v-if="!onPayment"></div>
            <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
          </el-button>
        </div>
      </div>
      <!-- !!! FC 18/04/24 Mobil odeme kısmı-->
      <div v-else class="credit-card">
        <div class="payment">
          <div class="title-payment">{{ $localization("web-interview-payment") }}</div>
          <p class="text-payment">{{ $localization("web-interview-please-enter-phone-info") }}</p>
        </div>
        <div class="phone">
          <vue-phone-number-input
            v-model="PhoneNumberText"
            @update="phoneFormatter = $event"
            :no-example="true"
            :no-country-selector="true"
            :translations="{
              countrySelectorLabel: $localization('web-login-country-code'),
              countrySelectorError: $localization('web-login-select-country-code'),
              phoneNumberLabel: $localization('web-login-phone-number'),
              example: $localization('web-login-example'),
            }"
            default-country-code="TR"
            class="primary-input input-bg-white" />
          <span v-if="$errorMessage('PhoneNumber', ValidationErrors) != null" class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
        </div>
        <div class="policy">
          <el-tooltip class="item" effect="dark" :content="$localization('web-interview-please-click')" placement="top">
            <div class="policy-container">
              <el-checkbox @change="checkboxChanged($event)" v-model="policyControl.policy1" :append-to-body="true"></el-checkbox>
              <div class="text">
                <span @click="openPolicy(8)">{{ $localization("web-interview-i-red-and-aggree-health-policy") }}</span>
              </div>
            </div>
          </el-tooltip>
        </div>
        <el-dialog :visible.sync="policyDialog" width="40%" custom-class="policy-dialog">
          <PolicyDialog :data="policy" @checked="checked" />
        </el-dialog>
        <el-button v-if="form.InterviewType == 2" class="start-pay primary-btn" @click="payment()" :disabled="onPayment" :loading="onPayment">
          <div class="icon" v-if="!onPayment"></div>
          <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
        </el-button>
        <el-button v-else class="start-pay primary-btn" @click="beforePayment()" :disabled="onPayment" :loading="onPayment">
          <div class="icon" v-if="!onPayment"></div>
          <span v-if="!onPayment">{{ $localization("web-interview-pay") }}</span>
        </el-button>
      </div>
      <div class="card-details">
        <div class="top">
          <div class="header">
            <div class="title">{{ $localization("web-interview-details") }}</div>
            <el-button type="text"> </el-button>
          </div>
          <div v-if="!form.PayTrPayment">
            <CreditCard :data="form" />
          </div>
        </div>
        <div class="bottom">
          <div class="item">
            <div class="title">{{ $localization("web-interview-meet-time") }}</div>
            <div class="desc" v-if="form.InterviewType">
              {{
                $localization(
                  "web-enum-interviewtype-" +
                    getEnums
                      .find((x) => x.Title == "InterviewType")
                      .Enums.find((x) => x.Key == form.InterviewType)
                      .Value.toLowerCase()
                )
              }}
            </div>
            <div class="desc" v-else>-</div>
          </div>
          <div class="item" v-if="form.InterviewType == 2">
            <div class="title">{{ $localization("web-interview-meet-time") }}</div>
            <div class="desc" v-if="date">{{ $moment(date).format("Do.MM.YYYY") }}</div>
          </div>
          <div class="item" v-if="form.InterviewType == 2">
            <div class="title">{{ $localization("web-interview-meet-date") }}</div>
            <!-- <div class="desc" v-if="form.AppointmentHour">{{ $moment(form.AppointmentHour).format("Do.MM.YYYY h:mm") }}</div> -->
            <div class="desc" v-if="hour">{{ hour }}</div>
          </div>
          <template v-if="!form.PayTrPayment">
            <div class="item">
              <div class="title">{{ $localization("web-interview-card-type") }}</div>
              <div class="desc">{{ $localization("web-interview-visa") }}</div>
            </div>
            <div class="item">
              <div class="title">{{ $localization("web-interview-cardholder") }}</div>
              <div class="desc">{{ form.CardName }}</div>
            </div>
            <div class="item">
              <div class="title">{{ $localization("web-interview-cardno") }}</div>
              <div class="desc">{{ form.CardNO }}</div>
            </div>
            <div class="item">
              <div class="title">{{ $localization("web-interview-expire-date") }}</div>
              <div class="desc">{{ form.ExpireDate }}</div>
            </div>
          </template>
          <div class="item">
            <div class="title">{{ $localization("web-interview-price") }}</div>
            <div class="desc">{{ ((appointmentDiscountPrice * 100) / (100 + taxRate)).toFixed(2) }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
          </div>
          <div class="item">
            <div class="title">{{ $localization("web-interview-tax") }}</div>
            <div class="desc">{{ (appointmentDiscountPrice - (appointmentDiscountPrice * 100) / (100 + taxRate)).toFixed(2) }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
          </div>
          <div class="item">
            <div class="title" style="color: #234a98">{{ $localization("web-interview-total-price") }}</div>
            <div class="desc" style="color: #234a98">{{ appointmentDiscountPrice }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="appointment-details" style="align-items: flex-start">
      <div class="policy">
        <el-tooltip class="item" effect="dark" :content="$localization('web-interview-please-click')" placement="top">
          <div class="policy-container">
            <el-checkbox @change="checkboxChanged($event)" v-model="policyControl.policy1" :append-to-body="true"></el-checkbox>
            <div class="text">
              <span @click="openPolicy(8)">{{ $localization("web-interview-i-red-and-aggree-health-policy") }}</span>
            </div>
          </div>
        </el-tooltip>
      </div>
      <el-dialog :visible.sync="policyDialog" width="40%" custom-class="policy-dialog">
        <PolicyDialog :data="policy" @checked="checked" />
      </el-dialog>
      <el-button v-if="form.InterviewType == 2" class="start-pay primary-btn" style="width: 70%; align-self: center" @click="payment()" :disabled="onPayment" :loading="onPayment">
        <div class="icon" v-if="!onPayment"></div>
        <span v-if="!onPayment">{{ $localization("web-interview-start-call", "Görüşmeyi Başlat") }}</span>
      </el-button>
      <el-button v-else class="start-pay primary-btn" style="width: 70%; align-self: center" @click="beforePayment()" :disabled="onPayment" :loading="onPayment">
        <div class="icon" v-if="!onPayment"></div>
        <span v-if="!onPayment">{{ $localization("web-interview-start-call", "Görüşmeyi Başlat") }}</span>
      </el-button>
    </div>

    <el-dialog :visible.sync="isClientOkDialog" :width="screenWidth > 991 ? '40%' : '90%'" class="check-mernis" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog">
        <div class="form">
          <div class="title">{{ $localization("web-interview-cam-mic-permission") }}</div>
          <div class="text">{{ $localization("web-interview-cam-mic-permission-description") }}</div>
          <div class="buttons">
            <el-button class="secondarary-btn" @click="isClientOkDialog = false">{{ $localization("web-interview-cancel") }}</el-button>
            <el-button class="primary-btn btn-shadow" @click="payment()">{{ $localization("web-interview-confirm") }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="isSmsDialog" :width="screenWidth > 991 ? '40%' : '90%'" class="check-mernis" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog">
        <div class="form">
          <div class="title">{{ $localization("web-interview-sms-confirm") }}</div>
          <div class="text">{{ $localization("web-interview-sms-confirm-description") }}</div>
          <div class="buttons">
            <el-button class="secondarary-btn" @click="smsCloseDialog()">{{ $localization("web-interview-sms-confirm-close") }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <AdditionalUpdateForm :show.sync="AdditionalUpdateFormShow"></AdditionalUpdateForm>
  </div>
</template>

<script>
import CreditCard from "../../components/CreditCard.vue";
import PolicyDialog from "../../components/PolicyDialog.vue";
import * as ActionNames from "@/store/action-names";
import AdditionalUpdateForm from "@/components/AdditionalUpdateProfileForm.vue";
export default {
  components: {
    CreditCard,
    PolicyDialog,
    AdditionalUpdateForm,
  },
  data() {
    return {
      currentMoneyType: null,
      countryList: [],
      allSpecialities: [],
      ValidationErrors: [],
      doctorsList: [],
      enableDoctorHours: [],
      screenWidth: 0,
      meetingType: 1,
      mernisCheck: false,
      isClientOkDialog: false,
      isSmsDialog: false,
      MobilePaymentOption: false,

      isClientOk: false,
      SpecialityId: null,
      date: null,
      hour: null,
      roomId: null,
      mernisForm: {
        FirstName: null,
        LastName: null,
        IdentityNumber: null,
        Birthdate: null,
      },
      test: null,
      policyDialog: false,
      policy: null,
      policyControl: {
        policy1: false,
        policy2: false,
      },
      activeCard: null,
      onPayment: false,
      payWithCard: false,
      saveCard: false,
      mask: {
        CardNO: "#### #### #### ####",
        ExpireDate: "##/##",
        CVV: "###",
        Identity: "###########",
      },
      form: {
        CardName: "",
        CardNO: "",
        CVV: "",
        ExpireDate: "",
        PatientId: null,
        InterviewType: 1,
        DoctorId: null,
        AppointmentDate: null,
        AppointmentHour: null,
        PayTrPayment: false, // !!! FC 16/04/24++,
        MobileNumber: null,
      },
      phone: "",
      phoneFormatter: null,
      PhoneNumberText: null, //"5333632646", //???? sil debug icim Bilalın numarası
      pickerOptions: {
        disabledDate(date) {
          const today = new Date();
          return date > new Date(today.getFullYear(), today.getMonth(), today.getDate() + 90) || date < new Date(today.getFullYear(), today.getMonth(), today.getDate());
        },
        firstDayOfWeek: 1,
      },
      AdditionalUpdateFormShow: false,
      log_transactionid: " ",
    };
  },

  computed: {
    appointmentList() {
      return this.$store.getters.getAppointments;
    },
    user() {
      return this.$store.getters.getUser;
    },
    children() {
      return this.$store.getters.getChildrenList.filter((x) => x.ParentId == this.user.ID);
    },
    patient() {
      var patient = this.children.concat(this.user);
      return patient;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    appointmentDiscountPrice() {
      return this.$store.getters.getDiscountAppointmentPrice;
    },
    taxRate() {
      return this.$store.getters.getTaxRate;
    },
    isFree() {
      return this.$store.getters.getIsFree;
    },
    CheckSmsShowDialog() {
      return this.$store.getters.getSmsShowDialog;
      //this.isSmsDialog = this.$store.getters.getSmsShowDialog;
    },
  },
  async created() {
    await this.checkAppointment();

    this.form.PatientId = this.patient[0].ID;

    this.$store.dispatch(ActionNames.GET_DISCOUNT_APPOINTMENT_PRICE); //await
    this.$store.dispatch(ActionNames.GET_TAX_RATE); //await
    this.$store.dispatch(ActionNames.GET_IS_FREE); //await
    //!!! FC 08/05/24++ Mobile ödeme enabled mi alıyoruz
    this.MobilePaymentOption = await this.$store.dispatch(ActionNames.GET_MOBILE_PAYMENT_ENABLED); //await
  },
  async beforeMount() {
    window.jQuery(document).ready(function () {
      window.jQuery(".slider").owlCarousel({
        items: 1,
        loop: true,
        margin: 30,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
      });
    });
    if (this.user.PaymentMethods.length > 0) {
      this.activeCard = this.user.PaymentMethods[0].Id;
    }
    var res = await this.$client.post("/Country/List", { SearchTerm: "", PageSize: 99999, Page: 1 });
    if (res.data.HasError) {
      this.$message.error(res.data.Message);
      setTimeout(async () => {
        this.$router.push("/");
      }, 2000);
    } else {
      this.countryList = res.data.Data.Items;
    }
    await this.$store.dispatch("getChildrenList", this.filter);
    await this.getAllSpecialities();
  },
  async mounted() {
    if (this.$gtag) {
      this.$gtag.pageview({ page_path: this.$route.path, page_title: "Interview | Elra Sağlık" });
    }

    await this.isMernisOk();
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    if (this.$route.query.expired) {
      this.$alert(this.$localization("web-interview-we-are-sorry-that-we-could-not-meet-you-with-our-doctor"), this.$localization("web-interview-information"), {
        dangerouslyUseHTMLString: true,
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
      });
    }
  },
  methods: {
    setMeetingType(type) {
      this.ValidationErrors = [];
      this.clearForm();

      if (type == 1) {
        this.meetingType = 1;
        this.form.InterviewType = 1;
      } else if (type == 2) {
        this.meetingType = 2;
        this.form.InterviewType = 2;
      }
      //  else if (type == 3) {
      //   this.meetingType = 3;
      // }
    },
    clearForm() {
      this.form.InterviewType = null;
      this.SpecialityId = null;
      this.form.DoctorId = null;
      this.date = null;
      this.hour = null;
      this.form.PayTrPayment = false; // !!! FC 16/04/24++
    },
    async getAllSpecialities() {
      await this.$client.post("/Speciality/List", { SearchTerm: null, Page: 1, PageSize: 1000 }).then((res) => {
        this.allSpecialities = res.data.Data.Items.filter((x) => x.IsActive);
      });
    },
    async setDoctors() {
      this.hour = null;
      this.date = null;
      this.form.DoctorId = null;
      await this.$client.post("/Speciality/DoctorList", { SpecialityId: this.SpecialityId }).then((res) => {
        this.doctorsList = res.data.Data.Items;
      });
    },
    async setEnableDoctorHour() {
      this.hour = null;
      await this.$client.post("/Speciality/CheckAvailableTimes", { DoctorId: this.form.DoctorId, AppointmentDate: this.date }).then((res) => {
        this.enableDoctorHours = res.data.Data.Items;
      });
    },
    resetDateAndHour() {
      this.date = null;
      this.hour = null;
    },
    updateTime(dateString, newTime) {
      const date = new Date(dateString);
      const timeParts = newTime.split(":");
      const hour = parseInt(timeParts[0], 10);
      const minute = parseInt(timeParts[1], 10);
      date.setHours(hour);
      date.setMinutes(minute);
      this.form.AppointmentDate = date;
    },
    async logout() {
      await this.$client.post("/Account/SignOut");
      localStorage.removeItem("token");
      this.$store.state.auth.user = null;
      this.$router.push({ name: "Login" });
    },
    async isMernisOk() {
      if (this.user.IsMernisVerified != true) {
        this.mernisCheck = true;
      }
    },
    async checkMernisRegistration() {
      var res = await this.$client.post("/Account/CheckMernisRegistration", this.mernisForm);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.mernisCheck = false;
        await this.$store.dispatch("getUser");
      }
    },
    async checkAppointment() {
      await this.getAppointmentList();
      var appointment = this.appointmentList.Appointments.filter((item) => item.Status == 1 || (item.Status == 2 && item.DoctorUser == null))[0];
      if (appointment) {
        this.$router.push("/kullanici/gorusme-odasi/" + appointment.ID);
      }
    },
    async getAppointmentList() {
      await this.$store.dispatch("getListUserAppointments", {
        PageSize: 100,
        SearchTerm: null,
        Status: null,
        Page: 1,
      });
    },
    checkboxChanged(val) {
      if (val) {
        // #region Analyticss
        if (this.$gtag) {
          this.$gtag.event("medical_consent", {
            platform: "web",
            user_id: this.user.ID,
          });
        }
        //#endregion
      }
    },
    checked(number, type) {
      var switchChecked = type == 1;
      switch (number) {
        case 8:
          this.policyControl.policy1 = switchChecked;
          this.checkboxChanged(switchChecked);
          break;
        case 2:
          this.policyControl.policy2 = switchChecked;
          break;
      }
      this.policyDialog = false;
    },
    async openPolicy(type) {
      var res = await this.$client.post("/Definition/GetPolicyByType", {
        Type: type,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.policy = res.data.Data.Policy;
        this.policyDialog = true;
      }
    },

    smsShowDialog() {
      this.$store.commit("setSmsShowDialog", true);
      this.isSmsDialog = true;
    },

    smsCloseDialog() {
      this.$store.commit("setSmsShowDialog", false);
      this.isSmsDialog = false;
    },

    async payment() {
      if (this.$gtag) {
        this.$gtag.event("click_pay_button", { platform: "web" });
      }
      this.ValidationErrors = [];
      this.isClientOkDialog = false;
      this.isClientOk = true;

      if (!this.policyControl.policy1) {
        this.$notify({
          title: this.$localization("web-interview-warning"),
          message: this.$localization("web-interview-you-must-aggree-policies"),
          type: "warning",
          duration: 2000,
        });
        this.loading = false;
        return;
      }
      if ((this.SpecialityId == null || this.SpecialityId == "") && (this.form.InterviewType == 2 || this.form.InterviewType == 3)) {
        this.$message.error(this.$localization("web-interview-please-select-speciality"));
        this.ValidationErrors.push({
          Key: "Speciality",
          Value: this.$localization("web-interview-please-fill-field"),
        });
        this.loading = false;
        return;
      }
      if (this.form.InterviewType == 2) {
        if (this.date == null || this.date == "") {
          this.$message.error(this.$localization("web-interview-please-select-date"));
          this.ValidationErrors.push({
            Key: "InterviewDate",
            Value: this.$localization("web-interview-please-fill-field"),
          });
          this.loading = false;
          return;
        } else {
          if (this.hour == null || this.hour == "") {
            this.$message.error(this.$localization("web-interview-please-select-hour"));
            this.ValidationErrors.push({
              Key: "InterviewHour",
              Value: this.$localization("web-interview-please-fill-field"),
            });
            this.loading = false;
            return;
          } else {
            this.updateTime(this.date, this.hour);
          }
        }
      }

      this.phone = "";
      if (this.form.PayTrPayment && !(this.isFree || this.appointmentDiscountPrice <= 0)) {
        if (this.phoneFormatter != null && this.phoneFormatter.isValid != false) {
          //!!! FC 19/04/24 substring  ile "+90" alıyoruz
          this.phone = this.phoneFormatter.formatInternational.replaceAll(" ", "").substring(3);

          this.email = null;
        } else {
          this.$message.warning(this.$localization("web-login-please-enter-valid-phone"));
          this.ValidationErrors.push({
            Key: "PhoneNumber",
            Value: this.$localization("web-login-please-enter-valid-phone"),
          });
          this.loading = false;
          return;
        }
      }

      this.onPayment = true;

      //!!! FC 19/04/24++ PayTr ödemesinde de kart bilgileri sıfırlandı
      if (this.isFree || this.appointmentDiscountPrice <= 0 || this.form.PayTrPayment) {
        this.form.CardNO = "0000000000000000";
        this.form.CardName = "Global";
        this.form.ExpireDate = "12/99";
        this.form.CVV = "000";
        this.saveCard = false;
      }

      var expireArray = this.form.ExpireDate.split("/");

      var res;

      if (this.form.PayTrPayment) {
        //sms dıalog kapama testi
        //this.smsShowDialog();
        //return;

        let paymentObj = {
          CardNo: this.form.CardNO.replace(/\s/g, ""),
          CardHolder: this.form.CardName,
          ExpireYear: expireArray[1],
          ExpireMonth: expireArray[0],
          Cvc: this.form.CVV,
          SaveCard: this.saveCard,
          AppointmentRequestFrom: 0,
          PatientId: this.form.PatientId,
          DoctorId: this.form.DoctorId,
          AppointmentDate: this.form.AppointmentDate,
          InterviewType: this.form.InterviewType,
          Permission: this.form.Permission,
          //!!! FC 19/04/24++ Mobil odeme
          IsMobilePayment: true,
          MobileNumber: this.phone,
          OperatorCode: null, // Şimdilik sadece turkcell olduğu için null göneriyoruz
        };

        res = await this.$client.post("/Appointment/Start", paymentObj);
        if (res.data.HasError) {
          this.ValidationErrors = res.data.ValidationErrors;
          this.$message.error(res.data.Message);
        } else {
          this.smsShowDialog();
          // #region Analyticss
          this.analyticPurchaseLog("mobile_payment");
          this.log_transactionid = this.user.ID;
          //#endregion
        }
      } else if (!this.payWithCard) {
        let paymentObj = {
          CardNo: this.form.CardNO.replace(/\s/g, ""),
          CardHolder: this.form.CardName,
          ExpireYear: expireArray[1],
          ExpireMonth: expireArray[0],
          Cvc: this.form.CVV,
          SaveCard: this.saveCard,
          AppointmentRequestFrom: 0,
          PatientId: this.form.PatientId,
          DoctorId: this.form.DoctorId,
          AppointmentDate: this.form.AppointmentDate,
          InterviewType: this.form.InterviewType,
          Permission: this.form.Permission,
          //!!! FC 19/04/24++ Mobil odeme degil
          IsMobilePayment: false,
          MobileNumber: null,
          OperatorCode: null,
        };
        res = await this.$client.post("/Appointment/Start", paymentObj);
        if (res.data.HasError) {
          this.ValidationErrors = res.data.ValidationErrors;
          this.$message.error(res.data.Message);
        } else {
          // #region Analyticss
          this.log_transactionid = this.user.ID;
          this.analyticPurchaseLog("new_credit_card");
          //#endregion

          if (res.data.Data.goToThreedHtmlPage) {
            window.location.href = res.data.Data.ThreedHtml;
          } else {
            window.jQuery("#hidden").html(res.data.Data.ThreedHtml);
            window.jQuery("#hidden").find("form").submit();
          }
        }
      } else {
        let res = await this.$client.post("/Appointment/StartWithCard", {
          PaymentMethodId: this.activeCard,
          AppointmentRequestFrom: 0,
          PatientId: this.form.PatientId,
          DoctorId: this.form.DoctorId,
          AppointmentDate: this.form.AppointmentDate,
          InterviewType: this.form.InterviewType,
          Permission: this.form.Permission,
        });
        if (res.data.HasError) {
          this.ValidationErrors = res.data.ValidationErrors;
          this.$message.error(res.data.Message);
        } else {
          // #region Analyticss
          this.log_transactionid = res.data.Data.Appointment.PaymentInfo.ConversationId;
          this.analyticPurchaseLog("saved_credit_card");
          //#endregion

          if (this.form.InterviewType == 2) {
            this.showSuccesDialogAndRedirect("/kullanici");
          } else {
            this.isClientOkDialog == true;

            if (this.isClientOkDialog == true) {
              if (this.$gtag) {
                this.$gtag.event("click_confirm_microphone", {
                  platform: "web",
                  user_id: this.user.ID,
                });
              } else {
                if (this.$gtag) {
                  this.$gtag.event("click_cancel_microphone", {
                    platform: "web",
                    user_id: this.user.ID,
                  });
                }
              }
            }

            this.roomId = res.data.Data.Appointment.ID;

            if (this.isFree || this.appointmentDiscountPrice <= 0) {
              this.$router.push("/kullanici/gorusme-odasi/" + this.roomId);
            } else {
              this.showSuccesDialogAndRedirect("/kullanici/gorusme-odasi/" + this.roomId);
            }
          }
        }
      }
      this.onPayment = false;
    },
    beforePayment() {
      if (this.calcAdditionalUpdateFormShow()) {
        return;
      }

      if (this.isClientOk == false) {
        this.isClientOkDialog = true;
      } else {
        this.payment();
      }
    },
    notOk() {
      this.isClientOkDialog = false;
      this.isClientOk = false;
    },
    analyticPurchaseLog(paymentMethod) {
      if (this.$gtag) {
        this.$gtag.purchase({
          currency: this.$store.state.auth.language == "tr" ? "TRY" : "USD",
          value: this.appointmentDiscountPrice,
          transaction_id: this.log_transactionid,
          items: [
            {
              item_brand: "Elra",
              item_name: "Online Görüşme",
            },
          ],
          parameters: {
            platform: "web",
            user_id: this.user.ID,
            quantity: "1",
            payment_method: paymentMethod,
          },
        });
        /*this.$gtag.event("purchase", {
          platform: "web",
          user_id: this.user.ID,
          price: this.appointmentDiscountPrice,
          currency: this.$store.state.auth.language == "tr" ? "TL" : "USD",
          quantity: "1",
          payment_method: paymentMethod,
        });*/
      }
    },
    showSuccesDialogAndRedirect(routeName) {
      this.$alert(this.$localization("web-interview-you-are-redirecting-meet-room"), this.$localization("web-interview-payment-succesfuly"), {
        confirmButtonText: this.$localization("web-interview-ok"),
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        type: "success",
        center: true,
        showClose: false,
        distinguishCancelAndClose: true,
      }).then(() => {
        /* {#1} */
      });

      // #1  dialogtan sonra yapılabilir (then)
      if (routeName !== null) {
        this.$message({
          type: "success",
          message: this.$localization("web-interview-you-are-redirecting-meet-room"),
        });
        this.$router.push(routeName);
      }
      // #1
    },
    calcAdditionalUpdateFormShow() {
      this.AdditionalUpdateFormShow = false;
      if (this.user.CitizenshipId == null) {
        this.AdditionalUpdateFormShow = true;
      } else {
        if (this.countryList.find((x) => x.ID == this.user.CitizenshipId) && this.countryList.find((x) => x.ID == this.user.CitizenshipId).Code == "TR") {
          if (this.user.District == null || this.user.City == null || this.user.IdentityNumber == null) {
            this.AdditionalUpdateFormShow = true;
          }
          if (!(this.user.IsMernisVerified ?? false)) {
            this.AdditionalUpdateFormShow = true;
          }
        } else if (this.user.PassportNumber == null) {
          this.AdditionalUpdateFormShow = true;
        }
      }
      if (this.user.BirthDate == null || this.user.Gender == null) {
        this.AdditionalUpdateFormShow = true;
      }

      return this.AdditionalUpdateFormShow;
    },
  },
  watch: {
    "$store.state.auth.language": function () {
      this.getPrice();
    },
    /*
    "$store.state.appointments.SmsShowDialog": function () {
      this.isSmsDialog = this.$store.getters.getSmsShowDialog;
    },
    */
    //Aynı isimle computed de olması lazım
    CheckSmsShowDialog(newValue) {
      // Computed değişimi takip ediyor
      this.isSmsDialog = newValue;
      if (!newValue && this.onPayment) {
        this.onPayment = false;
      }
    },
  },
};
</script>

<style lang="less">
.el-message-box {
  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
</style>

<template>
  <div class="blog-detail" v-if="blog">
    <div class="blog-detail-container">
      <div class="blog-cover-container">
        <img :src="blog.Cover" class="blog-detail-cover" :alt="blog.Title" />
        <div class="blog-cover-date">{{ $moment(blog.CreatedDateTime).format("DD MMMM YYYY") }}</div>
      </div>

      <div class="blog-detail-content">
        <div class="blog-detail-content-title">{{ blog.Title }}</div>
        <div class="blog-detail-content-text" v-html="blog.Details"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogDetail",
  data() {
    return {
      blog: null,
    };
  },
  mounted() {
    let blogId = this.$route.params.id;
    if (!blogId) {
      this.$router.push("/kullanici");
      return;
    }
    this.$client.post(`/Blog/GetBlogPost`, { Id: blogId }).then((response) => {
      this.blog = response.data.Data;
    });
  },
};
</script>

<style lang="less" scoped>
.blog-detail {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  min-height: calc(100vh - 80px);
  .blog-detail-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .blog-cover-container {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      .blog-cover-date {
        position: absolute;
        bottom: 0px;
        left: 0px;
        font-size: 12px;
        padding: 10px;
        background-color: rgba(43, 62, 140, 0.7);
        color: white;
        border-top-right-radius: 10px;
      }
      .blog-detail-cover {
        object-fit: cover;
        object-position: top;
        max-height: 500px;
      }
    }
  }
}
</style>

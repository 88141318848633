<script>
// core version + navigation, pagination, autoplay modules:
import Swiper, { Autoplay } from "swiper";
// import styles bundle
import "swiper/swiper-bundle.min.css";
export default {
  name: "ElraCommentsSlider",
  data() {
    return {
      activeIndex: 0,
      sliderItems: [],
    };
  },
  async beforeMount() {
    var response = await this.$client.post("AboutUs/GetUserComments", {});
    this.sliderItems = response.data.Data.Items;
  },
  mounted() {
    const SECOND = 1500; // milliseconds

    // init Swiper:
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Autoplay],
      loop: true,
      autoplay: {
        delay: 3 * SECOND,
        disableOnInteraction: false,
      },
      spaceBetween: 10,
      speed: 2 * SECOND,
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
      centeredSlides: false,
      // Events
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },
};
</script>

<template>
  <!-- Slider main container -->
  <div ref="swiper" class="swiper elra-comments-slider">
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide elra-comments-slider-slide" v-for="slideItem in sliderItems" :key="slideItem.ID">
        <div class="comments-user-name">{{ slideItem.FirstName[0] + slideItem.LastName[0] }}</div>
        <div class="comments-content">
          <div class="comments-content-text">{{ slideItem.Desc }}</div>
          <el-rate disabled :value="slideItem.Rate"></el-rate>
          <div class="comments-content-username">{{ slideItem.FirstName + " " + slideItem.LastName[0] + "." }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.elra-comments-slider {
  max-width: 100%;
  width: auto;
}

.elra-comments-slider-slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: white;
  width: 390px;
  background: #ffffff;
  user-select: none;
  border-radius: 10px;
  height: auto;
  padding: 16px 8px;
  .comments-content {
    display: flex;
    gap: 8px;
    flex-direction: column;
    .comments-content-text {
      color: black;
      font-size: 12px;
    }
    .comments-content-username {
      font-weight: bold;
      font-size: 11px;
      color: black;
    }
  }
  .comments-user-name {
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-weight: normal;
    font-size: 16px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
}
</style>

<template>
  <div id="policy-container">
    <div class="title">Hizmetler</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Ana Sayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Hizmetler</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="buttons-group">
        <el-button class="add" @click="setItem(null)"><i class="icon-plus"></i></el-button>
      </div>
    </div>

    <div class="all-data">
      <div class="list-table">
        <el-table :data="allproducts" style="width: 100%">
          <el-table-column label="Hizmet Adı">
            <template slot-scope="scope"> {{ scope.row.Title }} </template>
          </el-table-column>
          <el-table-column label="Hizmet Kodu" dth="200">
            <template slot-scope="scope"> {{ scope.row.ProductCode }} </template>
          </el-table-column>
          <el-table-column prop="price" label="FİYATI" width="130">
            <template slot-scope="scope"> {{ scope.row.ProductPrice }} TL </template>
          </el-table-column>
          <el-table-column label="Durum" width="300" align="start">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.IsActive" @change="setActivity(scope.row)" active-text="Aktif" inactive-text="Pasif" active-color="#234A98"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column width="300" align="center">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu">
                  <i class="icon-dots"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span style="display: block" @click="setItem(scope.row.ID)">Düzenle</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided><span class="tex-danger" style="display: block" @click="productDeleteQuery(scope.row.ID)">Sil</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Hizmet bulunamadı.</div>
              <div class="no-data-text">Herhangi bir Hizmet kaydı bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>

      <el-dialog :visible.sync="addUpdateDialog" width="50%" :close-on-click-modal="false">
        <div class="title">{{ form.Id == null ? "Hizmet Ekle" : "Hizmet Güncelle" }}</div>
        <div class="close" @click="addUpdateDialog = false">
          <i class="icon-close"></i>
        </div>
        <div class="body">
          <div class="part" v-if="form.Id != null">
            <input type="file" @change="uploadFile" style="display: none" ref="file" />
            <div class="image" @click="uploadFiles()" :style="{ backgroundImage: 'url(' + form.ProductImage + ')' }">
              <i class="icon-camera-2"></i>
              <div>Fotoğraf Yükle</div>
            </div>
          </div>
          <div class="input-container">
            <label>Hizmet Kodu</label>
            <el-input v-model="form.ProductCode"></el-input>
            <!-- C:\projects\VS\evimde_doktor\Internative\Internative.Helpers\ValidateModel.cs ilk karakteri kücük harf çevıriğinden InstituionCode
            ınstitutionCode yapıldı errorda 
            -->
            <span class="error">{{ $errorMessage("ınstitutionCode", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Hizmet Adı</label>
            <el-input v-model="form.Title"></el-input>
            <span class="error">{{ $errorMessage("Title", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Hizmet Fiyatı (KDV Dahil)</label>
            <el-input v-model="form.ProductPrice"></el-input>
            <span class="error">{{ $errorMessage("ProductPrice", ValidationErrors) }}</span>
          </div>
          <div class="part">
            <div class="header">Hizmet Açıklaması:</div>
            <!-- <el-input type="textarea" autosize placeholder="Bir şeyler yazın..." v-model="aboutUsNote"> </el-input> -->
            <quill-editor ref="myQuillEditor" v-model="form.ProductDescription" placeholder="Bir şeyler yazın..."> </quill-editor>
          </div>
          <div class="input-container">
            <label>Durum</label>
            <el-switch v-model="form.IsActive" active-text="Aktif" inactive-text="Pasif" active-color="#234A98"> </el-switch>
            <span class="error">{{ $errorMessage("IsActive", ValidationErrors) }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addUpdateDialog = false">İptal</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addUpdateProduct()"><i class="icon-send"></i>Kaydet</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        Id: null,
        Title: null,
        ProductCode: null,
        IsActive: true,
        ProductImage: null,
        ProductS3FileItemId: null,
        ProductPrice: 0,
        ProductDescription: null,
        Attachments: [],
      },
      allproducts: [],
      segmentationList: [],
      addUpdateDialog: false,
      onSave: false,
      ValidationErrors: [],
    };
  },

  async beforeMount() {
    await this.getData();
  },

  computed: {
    filter() {
      return this.$store.getters.getPolicyFilter;
    },

    getEnums() {
      return this.$store.getters.getEnums;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    async getData() {
      var res = await this.$client.post("/Product/List", { SearchTerm: null, Page: 1, PageSize: 1000 });
      this.allproducts = res.data.Data.Items;
      //console.log(this.allproducts);
    },
    async addUpdateProduct() {
      this.ValidationErrors = [];
      // this.onSave = true;

      //console.log("Gonderilen : ");
      //console.log(this.form);
      var res = await this.$client.post("/Product/AddOrUpdate", this.form);
      //console.log(res.data);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
        // this.onSave = false;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
        // this.onSave = false;
        this.addUpdateDialog = false;
        this.getData();
      }
    },

    async productDeleteQuery(id) {
      this.$confirm("Hizmet kaydı ve sepet atamaları silinecektir. Devam edilsin mi?", "Dikkat", {
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
        type: "warning",
      })
        .then(() => {
          this.deleteProduct(id);
        })
        .catch(() => {});
    },

    async deleteProduct(id) {
      var res = await this.$client.post("/Product/Delete", { ID: id });

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
        // this.onSave = false;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
        // this.onSave = false;
        this.addUpdateDialog = false;
        this.getData();
      }
    },

    setItem(id) {
      this.ValidationErrors = [];

      if (id == null) {
        this.form.Id = null;
        this.form.ProductCode = null;
        this.form.Title = null;
        this.form.IsActive = true;
        this.form.ProductPrice = 0;
        this.form.ProductDescription = null;
        this.form.ProductImage = null;
        this.form.ProductS3FileItemId = null;
      } else {
        var item = this.allproducts.find((x) => x.ID == id);
        this.form.Id = item.ID;
        this.form.ProductCode = item.ProductCode;
        this.form.Title = item.Title;
        this.form.IsActive = item.IsActive;
        this.form.ProductPrice = item.ProductPrice;
        this.form.ProductDescription = item.ProductDescription;
        this.form.ProductImage = item.ProductImage;
        this.form.ProductS3FileItemId = item.ProductS3FileItemId;
      }
      this.addUpdateDialog = true;
    },

    async setActivity(item) {
      var res = await this.$client.post("/Product/AddOrUpdate", item);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },

    async uploadFile() {
      let file = this.$refs.file.files[0];
      var fd = new FormData();
      fd.append("file", file);
      var res = await this.$client.post("/Product/UploadFile?type=8&RelatedItemId=" + this.form.Id, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.HasError != undefined && res.data.HasError == true) this.$message.error(res.data.Message);
      else {
        this.form.ProductImage = res.data.Item.Url;
        this.form.ProductS3FileItemId = res.data.Item.ID;
        console.log("formProductS3FileItemId : " + this.form.ProductS3FileItemId);
      }

      this.$refs.file.value = null;
    },
    uploadFiles() {
      this.$refs.file.click();
    },
    clearFiles(index) {
      this.form.Attachments.splice(index, 1);
    },
    fileSuccess(res) {
      this.form.Attachments.push(res.Item);
    },
  },
};
</script>

<style lang="less">
#policy-container {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }
  .part {
    .image {
      cursor: pointer;
      width: 170px;
      background-position: center;
      background-size: cover !important;
      height: 170px;
      background: #44566c;
      opacity: 0.6;
      border-radius: 40px;
      box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
      display: flex;
      flex-direction: column;
      justify-content: center;

      div,
      i {
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        align-self: center;
      }
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
      }

      .delete {
        background: #eaedf0;

        i {
          background: #44566c;
        }
      }

      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
        background: #234a98;

        i {
          background: white;
        }
      }
    }
  }

  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;

        &::before {
          height: 0;
        }
      }

      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }

      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }

      table td {
        border: none;
      }

      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }

      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }

      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }

        th:first-child {
          padding-left: 15px;
        }

        .cell {
          font-weight: 500;
        }
      }

      tbody {
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));

        .el-table__row {
          &:hover {
            background-color: white !important;
          }

          td {
            height: 60px;
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding-left: 15px;
          }

          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          background-color: white !important;
          border-radius: 10px !important;
        }

        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }

        td {
          .cell {
            i {
              height: 40px;
            }
          }
        }
      }
    }

    .el-dialog {
      width: 50%;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px;

      // .el-dialog__body {
      //   padding-bottom: 0;
      //   margin-top: 18px;
      // }
      .el-icon-close:before {
        content: "";
      }

      .el-dialog__header {
        display: none;
      }

      padding: 0;
      position: relative;

      .close {
        position: absolute;
        right: 1rem;
        cursor: pointer !important;
        top: 1rem;
        width: 28px;
        height: 28px;
        padding: 3px;
        border-radius: 50%;
        background-color: #eaedf0;

        .icon-close {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }

        input {
          background: #f8fafb;
          border: none;
        }

        .el-upload {
          width: 100%;
          border-color: #8697a8;
        }

        .el-select {
          width: 100%;
          border-color: #8697a8;
        }

        .text-edit {
          #toolbar {
            padding: 9px;
            border: none;
            background-color: #f8fafb !important;
            border-radius: 5px 5px 0px 0px;
            font-family: "Roboto" !important;
          }

          .ql-snow .ql-stroke {
            stroke: #8697a8;
          }

          .ql-active > .ql-snow .ql-stroke {
            stroke: #8697a8;
          }

          .ql-container {
            min-height: 100px;
            border: none !important;
            background-color: #f8fafb;
            resize: both;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #8697a8;
          }
        }

        .segment {
          width: 100%;
          height: 566px;
          box-shadow: 1px 4px 24px rgb(169 194 209 / 40%), 0px 2px 16px rgb(169 194 209 / 1%);
          background-color: #fff;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          padding: 30px;
          gap: 30px;

          .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            color: #44566c;
          }
        }
      }

      .dialog-footer {
        width: 100%;

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }

          .btn-add {
            filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));

            width: 152px;
            background: #234a98;
            height: 40px;
            padding: 0;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: white;
            border: none;

            i {
              float: left;
            }
          }

          .btn-cancel {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            width: 110px;
            color: #234a98;
            background-color: #eae5f8;
            border: none;
          }
        }
      }

      .second {
        .el-date-editor--daterange.el-input__inner {
          width: 225px;
          height: 40px;
          align-items: center;
          filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
          border: none;
          border-radius: 5px;

          ::placeholder {
            color: #44566c;
            font-size: 15px;
            font-weight: 400;
          }

          i {
            line-height: unset;
          }

          .icon-calendar {
            padding: 0 15px;
            background-color: #44566c;
          }
        }
      }
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .el-switch__label.is-active {
    color: #234a98;
  }
}
</style>

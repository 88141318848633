<template>
  <div id="categories">
    <div class="title">Kategoriler</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Blog</el-breadcrumb-item>
        <el-breadcrumb-item>Kategoriler</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group">
        <el-button class="add" @click="openAddOrUpdateDialog(null)"><i class="icon-plus"></i></el-button>
        <div v-if="multipleSelection.length > 0">{{ multipleSelection.length }} adet veri seçildi</div>
        <el-button class="delete" @click="deleteSelected" v-if="multipleSelection.length > 0"><i class="icon-delete"></i></el-button>
      </div>
    </div>

    <div class="all-data">
      <div class="list-table">
        <el-table ref="multipleTable" :data="categoryList" @selection-change="handleSelectionChange">
          <el-table-column sortable type="selection" align="center" style="text-center"> </el-table-column>
          <el-table-column sortable prop="OrderNo" label="SIRA NO" stripe width="120"> </el-table-column>
          <el-table-column sortable prop="Image" label="KATEGORİ RESMİ" stripe width="170">
            <template slot-scope="scope">
              <img :src="scope.row.Image" height="80" />
            </template>
          </el-table-column>
          <el-table-column sortable prop="Title" label="KATEGORİ ADI" stripe> </el-table-column>

          <el-table-column sortable prop="IsActive" label="DURUM" stripe width="120">
            <template slot-scope="scope">
              <el-switch active-color="#234A98" v-model="scope.row.IsActive" @change="changeStatus(scope.row.ID)"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="LanguageCode" label="DİL" width="80"> </el-table-column>

          <el-table-column sortable label="TARİH" stripe width="120">
            <template slot-scope="scope">
              <div class="dates">
                <div>
                  {{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY") }}
                </div>
                <div>
                  {{ $moment(scope.row.UpdatedDateTime).format("DD.MM.YYYY") }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="threeDot" align="center" label="" stripe width="60">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu">
                  <i class="icon-dots"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span @click="openAddOrUpdateDialog(scope.row)" style="display: block">Detayları Görüntüle</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span @click="deleteItemRow(scope.row.ID)" style="display: block">Sil</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Kategori bulunamadı.</div>
              <div class="no-data-text">Herhangi bir kategori bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>

      <el-dialog :visible.sync="addUpdateDialog" width="30%" :close-on-click-modal="false">
        <div class="title">Kategori Ekle/Düzenle</div>
        <div class="close" @click="addUpdateDialog = false">
          <i class="icon-close"></i>
        </div>
        <div class="body">
          <div class="input-container">
            <label>Sıra No</label>
            <el-input v-model="form.OrderNo" v-mask="'####################'"></el-input>
            <span class="error">{{ $errorMessage("OrderNo", validation) }}</span>
          </div>
          <div class="input-container">
            <label>Kategori Adı</label>
            <el-input v-model="form.Title"></el-input>
            <span class="error">{{ $errorMessage("Title", validation) }}</span>
          </div>
          <div class="input-container">
            <label>Kategori Resmi</label>
            <img :src="form.Image" width="100%" />
            <el-upload
              class="el-up"
              :action="$client.defaults.baseURL + 'Account/UploadFile?type=3'"
              :headers="{
                Authorization: $client.defaults.headers.common['Authorization'],
              }"
              :show-file-list="false"
              multiple
              :on-success="fileSuccess">
              <el-button size="small" style="justify-self: flex-start" type="primary">
                <i class="icon-plus"></i>
                Kategori Resmi Ekle</el-button
              >
            </el-upload>
            <span class="error">{{ $errorMessage("Image", validation) }}</span>
          </div>
          <label>Dil Seçiniz</label>
          <el-select v-model="form.LanguageCode" style="width: 100%">
            <el-option v-for="item in languageList" :key="item.ID" :label="item.Title" :value="item.LanguageCode" style="display: flex; align-items: center">
              <img :src="item.Image" style="height: 20px; width: 30px; margin-right: 10px" />
              {{ item.Title }}
            </el-option>
          </el-select>
          <div class="input-container">
            <label>Kategori Durumu</label>
            <el-switch active-color="#234A98" v-model="form.IsActive"> </el-switch>
            <span class="error">{{ $errorMessage("IsActive", validation) }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addUpdateDialog = false">İptal</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addOrUpdate"><i class="icon-send"></i>Kaydet</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addUpdateDialog: false,
      languageList: [],
      form: {
        ID: null,
        Title: null,
        OrderNo: null,
        Image: null,
        IsActive: false,
        LanguageCode: null,
      },
      multipleSelection: [],
    };
  },
  async beforeMount() {
    await this.$store.dispatch("getCategoryList", this.filter);
    await this.getLanguageList();
    this.form.LanguageCode = this.languageList[0].LanguageCode;
  },
  computed: {
    filter() {
      return this.$store.getters.getCategoryFilter;
    },
    categoryList() {
      return this.$store.getters.getCategoryList;
    },
    validation() {
      return this.$store.getters.getCategoryValidation;
    },
  },
  methods: {
    async getLanguageList() {
      var res = await this.$client.post("/Localization/SystemLanguages");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.languageList = res.data.Data.Items;
      }
    },
    fileSuccess(res) {
      if (res.HasError != undefined && res.HasError == true) this.$message.error(res.Message);
      else this.form.Image = res.Item.Url;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async deleteSelected() {
      await this.$store.dispatch("deleteAllCategory", this.multipleSelection);
    },
    async deleteItemRow(element) {
      await this.$store.dispatch("deleteOneCategory", element);
    },
    openAddOrUpdateDialog(item) {
      this.$store.commit("setCategoryValidation", []);
      if (item != null) {
        this.form.ID = item.ID;
        this.form.Title = item.Title;
        this.form.OrderNo = item.OrderNo;
        this.form.Image = item.Image;
        this.form.IsActive = item.IsActive;
      } else {
        this.form.ID = null;
        this.form.Title = null;
        this.form.OrderNo = null;
        this.form.Image = null;
        this.form.IsActive = false;
      }
      this.addUpdateDialog = true;
    },
    async addOrUpdate() {
      await this.$store.dispatch("addOrUpdateCategory", this.form);
      this.form = {
        ID: null,
        Title: null,
        OrderNo: null,
        Image: null,
        IsActive: false,
      };
      if (this.validation.length == 0) {
        this.addUpdateDialog = false;
      }
    },
    async changeStatus(id) {
      await this.$store.dispatch("changeStatusCategory", id);
    },
  },
};
</script>

<style lang="less">
#categories {
  padding: 42px 30px;

  .title {
    display: flex;
    gap: 10px;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #44566c;
    padding-bottom: 29px;
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
      }

      .delete {
        background: #eaedf0;

        i {
          background: #44566c;
        }
      }

      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
        background: #234a98;

        i {
          background: white;
        }
      }
    }
  }

  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;

        &::before {
          height: 0;
        }
      }

      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }

      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }

      table td {
        border: none;
      }

      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }

      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }

      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }

        .cell {
          font-weight: 500;
        }
      }

      tbody {
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
        height: auto;

        .el-table__row {
          &:hover {
            background-color: white !important;
          }

          td {
            height: 60px;
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          background-color: white !important;
          border-radius: 10px !important;
        }

        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }

        td {
          .cell {
            display: flex;
            align-items: center;
            gap: 10px;

            i {
              height: 40px;
            }
          }
        }
      }
    }

    .el-dialog {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 10px;

      .el-dialog__header {
        padding: 0;
      }

      .el-icon-close:before {
        content: "";
      }

      padding: 0;
      position: relative;

      .close {
        position: absolute;
        right: 1rem;
        cursor: pointer !important;
        top: 1rem;
        width: 28px;
        height: 28px;
        padding: 3px;
        border-radius: 50%;
        background-color: #eaedf0;

        .icon-close {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }

        input {
          background: #f8fafb;
          border: none;
        }

        .el-upload {
          width: 100%;
          border-color: #8697a8;
          text-align: start;
        }
      }

      .dialog-footer {
        width: 100%;
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .el-message-box {
    .el-message-box__btns {
      button {
        :last-child {
          background-color: #234a98;
          border: none;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>

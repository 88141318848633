<template>
  <div class="blogs-page-container">
    <h1>Blog Yazılarımız</h1>
    <div class="blog-list" v-infinite-scroll="loadMore" infinite-scroll-distance="10">
      <div class="blog-item" v-for="blog in blogList" :key="blog.ID">
        <blog-card :blog="blog" />
      </div>
    </div>
    <div v-if="noMoreData" class="no-more-data">Daha fazla blog yazısı bulunmamaktadır</div>
    <div v-if="loading" v-loading="loading" class="loading">Yükleniyor...</div>
  </div>
</template>

<script>
import BlogCard from "@/components/Blogs/BlogCard.vue";
export default {
  name: "Blogs",
  components: {
    BlogCard,
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      noMoreData: false,
      blogList: [],
    };
  },
  methods: {
    async loadMore() {
      if (this.loading || this.noMoreData) return;

      this.loading = true;
      await this.$store.dispatch("getBlogs", {
        PageSize: 10,
        Page: this.currentPage,
      });

      const newBlogs = this.$store.getters.getBlogs.Items || [];
      if (newBlogs.length === 0) {
        this.noMoreData = true;
      } else {
        this.blogList = [...this.blogList, ...newBlogs];
      }

      this.currentPage += 1;
      this.loading = false;
    },
  },
  created() {
    this.loadMore();
  },
};
</script>

<style lang="less">
.blogs-page-container {
  padding: 20px;
  background-color: #dce2ff;
  min-height: 100vh;

  .blog-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    @media screen and (max-width: 1200px) {
      display: flex;
    }
    .blog-card {
      max-height: 300px;
    }
  }

  .no-more-data {
    width: 100%;
    text-align: center;
    padding: 20px;
    color: #606266;
    font-size: 14px;
  }

  .loading {
    width: 100%;
    text-align: center;
    padding: 20px;
    color: #409eff;
    font-size: 14px;
  }
}
</style>

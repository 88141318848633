<script>
// core version + navigation, pagination, autoplay modules:
import Swiper, { Autoplay } from "swiper";
// import styles bundle
import "swiper/swiper-bundle.min.css";
export default {
  data() {
    return {
      activeIndex: 0,
      sliderItems: ["/entegrations-logo/enabiz-2.png", "/entegrations-logo/erapor.png", "/entegrations-logo/iso.png", "/entegrations-logo/Mernis.png", "/entegrations-logo/Recetem.png", "/entegrations-logo/SGK.png", "/entegrations-logo/Tse_Spice.png", "/entegrations-logo/Usbs Logo.png"],
    };
  },
  mounted() {
    const SECOND = 1000; // milliseconds

    // init Swiper:
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Autoplay],
      loop: true,
      autoplay: {
        delay: 3 * SECOND,
        disableOnInteraction: false,
      },
      speed: 2 * SECOND,
      breakpoints: {
        0: {
          slidesPerView: 4,
        },
        600: {
          slidesPerView: 6,
        },
        1024: {
          slidesPerView: 8,
        },
        1200: {
          slidesPerView: 11,
        },
      },
      slidesPerView: 1,
      centeredSlides: false,
      // Events
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },
};
</script>

<template>
  <!-- Slider main container -->
  <div ref="swiper" class="swiper custom-slider">
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide custom-slider-slide" v-for="(slideItem, index) in sliderItems" :key="index">
        <div class="custom-slider-logo">
          <img :src="slideItem" width="60" height="60" :alt="slideItem + '-e-logo'" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.custom-slider {
  max-width: 100%;
  width: auto;
  height: 75px;
  user-select: none;
}

.custom-slider-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: white;
  .custom-slider-logo {
    width: 75px;
    height: 75px;
    border-radius: 5px;
    background-color: #fff;
    padding: 7.5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.custom-slider-slider {
  border-radius: 10px;
  background-color: blue;

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .swiper-pagination-bullet {
      margin: 0 !important;
      height: 12px;
      width: 12px;
    }

    .swiper-pagination-bullet-active {
      background: white !important;
    }
  }
}
</style>
